import React, { useState, useEffect } from "react";

import "./Dashboard.css";

// Components
import Header from "../header/Header";
import Sidebar from "../Sidebar/Sidebar";
import { Select, MenuItem } from "@material-ui/core";
import Loader from "../../Loader/Loader";

// Images
import Customers from "../../Images/Customers.png";
import Users from "../../Images/Users.png";
import OrdersImg from "../../Images/Orders.png";
import Pending from "../../Images/Pending.png";
import { CSVLink } from "react-csv";

// Table
import Table from "../../Tables/Dashboard/DashboardTable";

// helpers
import { getRestraurants } from "../../helpers/restraurantHelper";
import { getRestraurantOrders, getAllOrders } from "../../helpers/orderHelper";
import { getAdminDashboard } from "../../helpers/adminHelper";

import SaveIcon from "@material-ui/icons/Save";

import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import moment from "moment";

const Dashboard = () => {
  //* useState hooks
  const [Orders, setOrders] = useState([]);
  const [Restraurants, setRestraurants] = useState([]);
  const [SelectedRestro, setSelectedRestro] = useState("");
  const [search, setSearch] = useState("");
  const [Loading, setLoading] = useState(true);
  const [Reload, setReload] = useState(false);
  const [Notifications, setNotifications] = useState([]);
  const [Values, setValues] = useState({
    users: "0",
    pendingOrders: "0",
    completeOrders: "0",
    acceptedOrders: "0",
  });

  const [DateValues, setDateValues] = useState({
    startDate: moment().format(),
    endDate: moment().format(),
  });

  const { users, pendingOrders, completeOrders, acceptedOrders } = Values;

  const { startDate, endDate } = DateValues;

  console.log("VALUES", Values);

  const Cards = [
    {
      cardImg: OrdersImg,
      cardName: "Total Orders",
      data: completeOrders,
    },
    {
      cardImg: Customers,
      cardName: "Total Customers",
      data: users,
    },
    {
      cardImg: Pending,
      cardName: "Pending Orders",
      data: pendingOrders,
    },
    {
      cardImg: Users,
      cardName: "Total Accepted Orders",
      data: acceptedOrders,
    },
  ];

  // getRestraurants
  const getAllRestraurants = () => {
    getRestraurants()
      .then((data) => {
        setRestraurants(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  // getDashboardDetails
  const getDashboardDetails = () => {
    getAdminDashboard()
      .then((data) => {
        const {
          ordersLength,
          pendingOrdersLength,
          usersLength,
          acceptedOrders,
        } = data.data;
        console.log("DASHBOARD", data.data);
        setValues({
          users: usersLength,
          pendingOrders: pendingOrdersLength,
          completeOrders: ordersLength,
          acceptedOrders: acceptedOrders,
        });
        setNotifications(pendingOrdersLength);
      })
      .catch((err) => console.error(err));
  };

  // getAllOrders
  const getOrders = () => {
    getAllOrders(
      new Date(startDate).toISOString(),
      new Date(endDate).toISOString()
    )
      .then((data) => {
        console.log("ALL ORDERS", data);
        setOrders(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // getRestraurantsOrders
  const getRestroOrders = (_id) => {
    setLoading(true);

    getRestraurantOrders(_id)
      .then((data) => {
        console.log("RESTRO Orders", data);
        setOrders(data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  // useEffect Hooks
  useEffect(() => {
    getOrders();
    getAllRestraurants();
    getDashboardDetails();
  }, [Reload]);

  useEffect(() => {
    if (SelectedRestro) {
      getRestroOrders(SelectedRestro);
    }
  }, [SelectedRestro]);

  // changeHandler
  const changeHandler = (e) => {
    if (e.target.value == "All") {
      setReload(!Reload);
      return "";
    }
    setSelectedRestro(e.target.value);
  };

  console.log("RESTRO's", Restraurants);
  console.log("SELECTED RESTRO", SelectedRestro);
  console.log("ORDERS", Orders);

  let minDate = moment().format();

  const headers = [
    { label: "UserId", key: "user._id" },
    { label: "Username", key: "user.name" },
    { label: "User Email", key: "user.email" },
    { label: "Contact", key: "user.contact" },
    { label: "OrderId", key: "_id" },
    { label: "Delivery Type", key: "delieveryType" },
    { label: "Total Amount", key: "totalAmount" },
    { label: "Payment Type", key: "paymentType" },
    { label: "Delivery Address", key: "delieveryAddress" },
    { label: "Restro Id", key: "restro" },
  ];

  const csvReport = {
    data: Orders,
    headers: headers,
    filename: "Orders.csv",
  };

  // dateHandler
  const dateHandler = (name) => (e) => {
    console.log(e);
    let value = e;
    setDateValues({ ...DateValues, [name]: value });
    setReload(!Reload);
  };

  // Component Return
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div style={{ padding: "0" }} className="col-md-2">
            <Sidebar />
          </div>
          {Loading ? (
            <div
              style={{
                height: "90vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="col-md-10"
            >
              <Loader />
            </div>
          ) : (
            <div
              className="col-md-10"
              style={{
                backgroundColor: "#F6F8FB",
                height: "100vh",
              }}
            >
              <Header
                search={search}
                setSearch={setSearch}
                setNotifications={setNotifications}
                Notifications={Notifications}
              />
              <div className="below-header">
                <h1>Dashboard</h1>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <button className="export-btn">
                    <CSVLink
                      onClick={Orders.length > 0 && null}
                      {...csvReport}
                      target="_blank"
                    >
                      Export
                    </CSVLink>
                    <SaveIcon />
                  </button>

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      // label="Start Date"
                      variant="inline"
                      autoOk={true}
                      // InputLabelProps={{ shrink: true, required: true }}
                      InputProps={{ inputProps: { min: minDate } }}
                      defaultValue={minDate}
                      value={startDate}
                      onChange={dateHandler("startDate")}
                      // format="yyyy-MM-dd"
                      inputVariant="outlined"
                      style={{ width: "200px" }}
                      mindate={minDate}
                    />
                  </MuiPickersUtilsProvider>

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      // label="End Date"
                      variant="inline"
                      autoOk={true}
                      // InputLabelProps={{ shrink: true, required: true }}
                      InputProps={{ inputProps: { min: minDate } }}
                      defaultValue={minDate}
                      value={endDate}
                      onChange={dateHandler("endDate")}
                      // format="yyyy-MM-dd"
                      inputVariant="outlined"
                      style={{ width: "200px" }}
                      mindate={minDate}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>

              <div className="cards-wrapper">
                {Cards.map((card, index) => (
                  <div className="dashboard-card" key={index}>
                    <div className="card-img-wrapper">
                      <img src={card.cardImg} alt="img" />
                    </div>
                    <div>
                      {card.cardName}
                      <h3>{card.data}</h3>
                    </div>
                  </div>
                ))}
              </div>
              <div className="select-card-wrapper">
                <div className="select-card">
                  <h3>Recent Order Request</h3>

                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    defaultValue="All"
                    onChange={changeHandler}
                  >
                    <MenuItem value="All" selected>
                      All
                    </MenuItem>
                    {Restraurants &&
                      Restraurants.map((val) => (
                        <MenuItem key={val._id} value={val._id}>
                          {val.restroName}
                        </MenuItem>
                      ))}
                  </Select>
                </div>
              </div>
              <Table
                search={search}
                rows={Orders}
                columns={[
                  "Order-id",
                  "Order Menu",
                  "Order Time",
                  "Delivery Type",
                  "Order Status",
                  "Amount",
                ]}
                style={{ boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.3)" }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
