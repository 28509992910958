import React from "react";
import "./Tabs.css";

// components
import RestraurantDetails from "../Settings/RestraurantDetails";
import Schedule from "../Settings/Schedule";
import OrderAndDelivery from "../Settings/OrderAndDelievery";

const Tabs = ({ CurrentPage, setCurrentPage, Pages }) => {
  const currentTab = (name) => {
    if (name == CurrentPage) {
      return "tab-active";
    } else {
      return "";
    }
  };

  return (
    <>
      <div className="multiple-tabs">
        {Pages.map((page) => {
          const { name, index } = page;
          return (
            <div key={index}>
              <h3
                style={{ fontSize: "20px" }}
                className={currentTab(name)}
                onClick={() => {
                  setCurrentPage(name);
                }}
              >
                {name}
              </h3>
            </div>
          );
        })}
      </div>
      {CurrentPage === "Restaurant Details" && (
        <RestraurantDetails
          CurrentPage={CurrentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
      {/* {CurrentPage === "Schedule" && (
        <Schedule CurrentPage={CurrentPage} setCurrentPage={setCurrentPage} />
      )} */}
      {/* {CurrentPage === "Timing" && (
        <OrderAndDelivery
          CurrentPage={CurrentPage}
          setCurrentPage={setCurrentPage}
        />
      )} */}
    </>
  );
};

export default Tabs;
