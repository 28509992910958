import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { Select, MenuItem } from "@material-ui/core";

// helpers
import { updateUserAccountStatus } from "../../helpers/customerHelper";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.common.black,
    padding: "16px !important",
    fontSize: "15px",
  },
  body: {
    fontSize: "14px",
  },
  root: {
    padding: "4px 16px",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function CustomizedTables(props) {
  const classes = useStyles();

  const changeHandler = (customerId) => (e) => {
    console.log(customerId);
    console.log("STATUS", e.target.value);

    updateUserAccountStatus(customerId)
      .then((data) => {
        console.log("DATA", data);
        props.setReload(!props.Reload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log("User Orders", props.UsersOrders);

  const ordersCountHandler = (userId) => {
    for (let order of props.UsersOrders && props.UsersOrders) {
      // console.log("userId", userId);
      console.log("order", order.userinfo);
      // console.log("order user", order?.userInfo?._id);
      if (order?.userinfo?._id == userId) {
        return order.count;
      }
    }
    return 0;
  };

  console.log("order count", ordersCountHandler("61c088820db2d12ab8639832"));

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              {props.columns.map((column, index) => {
                return (
                  <StyledTableCell key={index} align="left">
                    {column}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows &&
              props.rows
                .filter((user) => {
                  if (
                    props.search &&
                    props.search.toLowerCase().trim() === ""
                  ) {
                    return user;
                  } else if (
                    user._id.includes(props.search.toLowerCase()) ||
                    user.name
                      .toLowerCase()
                      .includes(props.search.toLowerCase()) ||
                    (user.email &&
                      user.email
                        .toLowerCase()
                        .includes(props.search.toLowerCase()))
                  ) {
                    return user;
                  }
                })
                .reverse()
                .map((row) => (
                  <TableRow key={row._id}>
                    <TableCell component="th" scope="row">
                      {row._id}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.name && row.name ? row.name : "N/A"}
                    </TableCell>
                    <TableCell align="left">{row.email && row.email}</TableCell>
                    <TableCell align="left">
                      {ordersCountHandler(row._id)}
                    </TableCell>
                    <TableCell align="left">
                      {row.contact && row.contact ? row.contact : "N/A"}
                    </TableCell>
                    <TableCell align="left">
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={row.activeAccount && row.activeAccount}
                        onChange={changeHandler(row._id)}
                      >
                        <MenuItem value={true}>Active</MenuItem>
                        <MenuItem value={false}>In-Active</MenuItem>
                      </Select>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      {props.rows.length == 0 && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100px",
            fontSize: 18,
          }}
        >
          <p>Currently No Customers</p>
        </div>
      )}
    </>
  );
}
