import React, { useState, useEffect } from "react";

// components
import Loader from "../../Loader/Loader";

// material-ui
import { IconButton, Select, MenuItem } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

// helpers
import {
  getPendingOrders,
  acceptOrRejectOrder,
} from "../../helpers/orderHelper";
import { isAuthenticated } from "../../helpers/AuthHelper";
import { getRestraurants } from "../../helpers/restraurantHelper";

import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";

const PendingOrders = ({ search }) => {
  // useState hooks
  const [Orders, setOrders] = useState([]);
  const [Reload, setReload] = useState(false);
  const [Loading, setLoading] = useState(true);
  const [Restraurants, setRestraurants] = useState([]);
  const [SelectedRestro, setSelectedRestro] = useState("");

  // getAllOrderRequests
  const getAllOrderRequests = (SelectedRestro) => {
    getPendingOrders(SelectedRestro)
      .then((data) => {
        console.log("Data", data);
        setOrders(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const getAllRestraurants = () => {
    getRestraurants()
      .then((data) => {
        setRestraurants(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  // useEffect
  useEffect(() => {
    getAllOrderRequests(SelectedRestro);
    getAllRestraurants();
  }, [Reload, SelectedRestro]);

  // acceptOrRejectHandler
  const acceptOrRejectHandler = (orderId, answer) => {
    setLoading(true);

    let adminId = isAuthenticated().data.user;
    const { _id } = adminId;
    console.log("ADMIN ID", _id);

    acceptOrRejectOrder(_id, orderId, { isAccepted: answer })
      .then((data) => {
        console.log(data);
        setLoading(false);
        setReload(!Reload);
        toast.success(data.message);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error("Something went wrong!");
      });
  };

  const changeHandler = (e) => {
    if (e.target.value == "All") {
      // setReload(!Reload);
      // return "";
      setSelectedRestro(e.target.value);
    }
    setSelectedRestro(e.target.value);
  };

  console.log("Selected Restro", SelectedRestro);

  // component return
  return (
    <div>
      {Loading ? (
        <div
          style={{
            width: "100%",
            minHeight: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="col-md-10"
        >
          <Loader />
        </div>
      ) : (
        <div>
          <Toaster />
          <Select
            style={{ margin: "5px" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            defaultValue="All"
            onChange={changeHandler}
          >
            <MenuItem value="All" selected>
              All
            </MenuItem>
            {Restraurants &&
              Restraurants.map((val) => (
                <MenuItem key={val._id} value={val._id}>
                  {val.restroName}
                </MenuItem>
              ))}
          </Select>
          <div className="order-request-cards-wrapper">
            {Orders && Orders.length == 0 && (
              <h3 className="mt-5 text-center">No Order Requests</h3>
            )}
            {Orders &&
              Orders.filter((data) => {
                if (search && search.toLowerCase() == "") {
                  return data;
                } else if (data._id.includes(search.toLowerCase())) {
                  return data;
                }
              })
                .reverse()
                .map((order) => {
                  const {
                    _id,
                    totalAmount,
                    delieveryType,
                    orderItems,
                    restro,
                    customerNote,
                    paymentType,
                  } = order;
                  return (
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "2fr 1fr 1fr 1fr",
                        alignItems: "center",
                        textAlign: "center",
                        boxShadow: "0 0 4px rgba(0,0,0,0.4)",
                        marginTop: "10px",
                        padding: "10px",
                        background: "white",
                      }}
                      className="order-request-card"
                      key={_id}
                    >
                      <div
                        style={{
                          textAlign: "left",
                        }}
                      >
                        <h4 style={{ fontSize: "16px", fontWeight: "500" }}>
                          Order id -
                          <Link to={`/admin/dashboard/order/${_id}`}>
                            <span> {_id}</span>
                          </Link>
                        </h4>

                        {/* <h5 style={{ fontSize: "20px" }}>
                          Delivery Time -{" "}
                          {restro ? `${restro.prepTime} min` : ""}
                        </h5> */}
                      </div>
                      <div>
                        {orderItems.length > 1 ? (
                          <>
                            <h5
                              style={{
                                fontSize: "20px",
                                padding: "10px",
                                border: "1px dotted",
                                backgroundColor: "#EDEDED",
                              }}
                            >
                              {orderItems[0].name}+{orderItems.length - 1}
                            </h5>
                            <span
                              style={{
                                fontSize: "16px",
                                color: "#717171",
                              }}
                            >
                              Customer Note :
                              {customerNote != " " && customerNote.length > 10
                                ? `${customerNote.substring(0, 10)}...`
                                : customerNote}
                            </span>
                          </>
                        ) : (
                          <>
                            <h5
                              style={{
                                padding: "10px",
                                border: "1px dotted",
                                backgroundColor: "#EDEDED",
                              }}
                            >
                              {orderItems && orderItems[0].name}
                            </h5>
                            <span>
                              Customer Note :
                              {customerNote && customerNote.length > 15
                                ? `${customerNote.substring(0, 15)}...`
                                : `${customerNote}`}
                            </span>
                          </>
                        )}
                      </div>
                      <div>
                        <h4>AED {totalAmount}</h4>
                        <h5>{paymentType && paymentType}</h5>
                      </div>
                      <div>
                        <IconButton
                          onClick={() => {
                            acceptOrRejectHandler(order._id, true);
                          }}
                          variant="contained"
                          color="primary"
                        >
                          <CheckIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            acceptOrRejectHandler(order._id, false);
                          }}
                          variant="contained"
                          color="secondary"
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </div>
                  );
                })}
          </div>
          {/* </ScrollableFeed> */}
        </div>
      )}
    </div>
  );
};

export default PendingOrders;
