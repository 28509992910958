import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { Link } from "react-router-dom";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.common.black,
    padding: "16px !important",
    fontSize: "15px",
  },
  body: {
    fontSize: "14px",
  },
  root: {
    padding: "4px 16px",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function CustomizedTables(props) {
  const classes = useStyles();

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              {props.columns.map((column, index) => {
                return (
                  <StyledTableCell key={index} align="left">
                    {column}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows &&
              props.rows
                .filter((data) => {
                  if (props.search && props.search.toLowerCase() == "") {
                    return data;
                  } else if (data._id.includes(props.search.toLowerCase())) {
                    return data;
                  }
                })
                .reverse()
                .map((row) => (
                  <TableRow key={row._id}>
                    <TableCell component="th" scope="row">
                      <Link to={`/admin/dashboard/order/${row._id}`}>
                        {row._id}
                      </Link>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.orderItems.length > 1
                        ? `${row.orderItems[0].name} + ${
                            row.orderItems.length - 1
                          }`
                        : `${row.orderItems[0].name}`}
                    </TableCell>
                    <TableCell align="left">
                      {row.createdAt && row.createdAt.slice(11, -5)}
                    </TableCell>
                    <TableCell align="left">
                      {row.delieveryType === "Delivery"
                        ? "Home Delivery"
                        : "Take Away"}
                    </TableCell>
                    <TableCell align="left">
                      {row.status === "PENDING" && (
                        <span className="badge bg-secondary">Pending</span>
                      )}
                      {row.status == "APPROVED" && (
                        <span className="badge bg-primary">Approved</span>
                      )}
                      {row.status == "REJECTED" && (
                        <span className="badge bg-danger">Rejected</span>
                      )}
                      {row.status == "PREPARING" && (
                        <span className="badge bg-success">Preparing</span>
                      )}
                      {row.status == "OUTFORDELIVERY" && (
                        <span className="badge bg-info">Out for Delivery</span>
                      )}
                      {row.status == "DELIVERED" && (
                        <span className="badge bg-success">Delivered</span>
                      )}
                    </TableCell>
                    <TableCell align="left">AED {row.totalAmount}</TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      {props.rows.length == 0 && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100px",
            fontSize: 18,
          }}
        >
          <p>Currently No Orders</p>
        </div>
      )}
    </>
  );
}
