import React from "react";

import { Link, withRouter } from "react-router-dom";
import "./Sidebar.css";

import Logo from "../../Images/Logo.png";

//* Material
import RestaurantMenuIcon from "@material-ui/icons/RestaurantMenu";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import PersonIcon from "@material-ui/icons/Person";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import SettingsIcon from "@material-ui/icons/Settings";
import HelpIcon from "@material-ui/icons/Help";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import PaymentIcon from "@material-ui/icons/Payment";
import CategoryIcon from "@material-ui/icons/Category";
import GroupIcon from "@material-ui/icons/Group";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";

import { logout } from "../../helpers/AuthHelper";

// currentTab
const currentTab = (histoy, path) => {
  if (histoy.location.pathname == path) {
    return "active";
  } else {
    return "";
  }
};

const Links = [
  {
    name: "Overview",
    path: "/admin/dashboard/overview",
    icon: <RemoveRedEyeIcon />,
  },
  {
    name: "Orders",
    path: "/admin/dashboard/orders",
    icon: <PaymentIcon />,
  },
  {
    name: "Food Menu",
    path: "/admin/dashboard/menu",
    icon: <CategoryIcon />,
  },
  {
    name: "Customers",
    path: "/admin/dashboard/all/customers",
    icon: <GroupIcon />,
  },

  {
    name: "Transactions",
    path: "/admin/dashboard/transactions",
    icon: <AccountBalanceWalletIcon />,
  },
  {
    name: "Offers",
    path: "/admin/dashboard/offers",
    icon: <LocalOfferIcon />,
  },
  {
    name: "Users",
    path: "/admin/dashboard/users",
    icon: <PeopleAltIcon />,
  },
  {
    name: "Help",
    path: "/admin/dashboard/help",
    icon: <HelpIcon />,
  },
  {
    name: "Settings",
    path: "/admin/dashboard/setting",
    icon: <SettingsIcon />,
  },
];

const Sidebar = ({ history }) => {
  return (
    <>
      <div className="sidebar">
        <div
          style={{
            width: "50%",
            textAlign: "center",
            margin: "15px auto",
          }}
        >
          <img
            style={{ width: "100%", objectFit: "contain" }}
            src={Logo}
            alt="logo"
          />
        </div>
        <ul>
          {Links.map((link, index) => (
            <li key={index}>
              <Link
                className={currentTab(history, link.path)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flexStart",
                }}
                to={link.path}
              >
                <span style={{ marginRight: "10px", color: "#DBDDE0" }}>
                  {" "}
                  {link.icon}
                </span>
                {link.name}
              </Link>
            </li>
          ))}
        </ul>
        <button
          style={{
            width: "80%",
            padding: "8px 10px",
            margin: "0 auto",
            fontSize: "17px",
            cursor: "Pointer",
            background: "black",
            color: "white",
            borderRadius: "5px",
            border: "none",
          }}
          onClick={() =>
            logout(() => {
              history.push("/");
            })
          }
        >
          Logout
        </button>
      </div>
    </>
  );
};

export default withRouter(Sidebar);
