import React, { useState, useEffect } from "react";
import "./TicketReply.css";

// components
import Header from "../header/Header";
import Sidebar from "../Sidebar/Sidebar";

import Loader from "../../Loader/Loader";
import { IconButton, Select } from "@material-ui/core";
import { useParams } from "react-router-dom";

import { getTicket, updateTicketStatus } from "../../helpers/TicketHelpers";
import ReplyIcon from "@material-ui/icons/Reply";

import { useHistory } from "react-router-dom";

const TicketReply = () => {
  // parameter extraction
  const { ticketId } = useParams("ticketId");

  // useState hooks
  const [Ticket, setTicket] = useState("");
  const [Loading, setLoading] = useState(true);
  const [Reload, setReload] = useState(false);
  const [Values, setValues] = useState({
    status: "",
  });

  // destructuring
  const { status } = Values;

  // getTicketInfo
  const getTicketInfo = (ticketId) => {
    getTicket(ticketId)
      .then((data) => {
        console.log(data);
        setValues({ ...Values, status: data.status });
        setTicket(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  // useEffect
  useEffect(() => {
    getTicketInfo(ticketId);
  }, [Reload]);

  // updateTicket
  const handleChange = (e) => {
    setLoading(true);

    let status = e.target.value;

    updateTicketStatus(ticketId, { status })
      .then((data) => {
        console.log(data);
        setReload(!Reload);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(true);
      });
  };

  const history = useHistory();

  // component return
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2">
            <Sidebar />
          </div>

          {Loading ? (
            <div
              className="col-md-10"
              style={{
                height: "90vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader />
            </div>
          ) : (
            <div
              style={{ backgroundColor: "rgb(246, 248, 251)" }}
              className="col-md-10"
            >
              <Header />
              <h1>Help</h1>
              <IconButton
                style={{ padding: "10px" }}
                onClick={() => history.goBack()}
                variant="contained"
                color="primary"
              >
                <ReplyIcon />
              </IconButton>

              <div className="reply-card mt-5 w-100">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "10px",
                  }}
                >
                  <div>
                    <h3>{Ticket.title}</h3>
                  </div>
                  <h5 style={{ color: "#9AA3AB" }}>
                    {Ticket.createdAt.slice(0, 10)}
                  </h5>
                </div>
                <div style={{ padding: "10px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <h3
                          style={{
                            height: "78px",
                            width: "78px",
                            left: "32px",
                            top: "72px",
                            borderRadius: "0px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: "#2BBFC5",
                            color: "white",
                          }}
                        >
                          {Ticket.user && Ticket.user
                            ? Ticket.user.name.slice(0, 1)
                            : "Username"}
                        </h3>
                        <div style={{ marginLeft: "10px" }}>
                          <h4 style={{ color: "#9AA3AB", fontSize: "17px" }}>
                            {" "}
                            {Ticket.user && Ticket.user
                              ? Ticket.user.name
                              : "Username"}
                          </h4>
                          <h4 style={{ color: "#9AA3AB", fontSize: "17px" }}>
                            {Ticket.createdAt.slice(12, -11) > 12
                              ? `${Ticket.createdAt.slice(12, -8)} AM`
                              : `${Ticket.createdAt.slice(12, -8)} PM`}
                          </h4>
                        </div>
                      </div>
                    </div>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      defaultValue={status}
                      onChange={handleChange}
                    >
                      <option value="OPEN">Open</option>
                      <option value="CLOSE">Close</option>
                    </Select>
                  </div>
                  <br />
                  <h4
                    className="query"
                    style={{ fontSize: "18px" }}
                    className="text-secondary"
                  >
                    Ticket Description
                  </h4>
                  <h5
                    className="query"
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {Ticket.query}
                  </h5>
                  <br />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TicketReply;
