import React from "react";

const OtpInput = ({ Otp, setOtp }) => {
  //* changeHandler
  const changeHandler = (elem, ind) => {
    if (isNaN(elem.value)) return false;

    setOtp([...Otp.map((d, i) => (i === ind ? elem.value : d))]);

    // focus on next input
    if (elem.nextSibling) {
      elem.nextSibling.focus();
    }
  };

  console.log("OTP", Otp);

  //* multiple inputs
  const Inputs = Otp.map((val, index) => (
    <input
      key={index}
      style={{
        textAlign: "center",
        width: "100px",
        margin: "5px 10px",
      }}
      className="otp-input"
      type="text"
      maxLength="1"
      onChange={(e) => changeHandler(e.target, index)}
      onFocus={(e) => e.target.select()}
    />
  ));

  return <form className="otp-form">{Inputs}</form>;
};

export default OtpInput;
