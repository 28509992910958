import { API } from "../Backend";

// getTransactions
export const getTransactions = () => {
  return fetch(`${API}/api/admin/get/transactions`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};
