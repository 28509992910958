import { API } from "../Backend";

// getTicket
export const getTicket = (ticketId) => {
  return fetch(`${API}/api/ticket/${ticketId}/get`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

// sendResponse
export const updateTicketStatus = (ticketId, data) => {
  return fetch(`${API}/api/admin/ticket/${ticketId}/update/status`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};
