import React, { useState, useEffect } from "react";

// components
import Loader from "../../Loader/Loader";
import Table from "../../Tables/Orders/OrdersTable";
import toast, { Toaster } from "react-hot-toast";

// helpers
import { getOrders } from "../../helpers/orderHelper";
import { getRestraurants } from "../../helpers/restraurantHelper";

//? material-ui
import { Select, MenuItem } from "@material-ui/core";

const Orders = ({ search }) => {
  // useState hooks
  const [Orders, setOrders] = useState([]);
  const [Reload, setReload] = useState(false);
  const [Loading, setLoading] = useState(true);
  const [Restraurants, setRestraurants] = useState([]);
  const [SelectedRestro, setSelectedRestro] = useState("");

  let restroId = "60fbe714609e558ff0d1f762";

  // getAllOrderRequests
  const getAllOrders = (SelectedRestro) => {
    getOrders(SelectedRestro)
      .then((data) => {
        console.log("ALL ORDERS", data);
        setOrders(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error("Something went Wrong!");
      });
  };

  const getAllRestraurants = () => {
    getRestraurants()
      .then((data) => {
        setRestraurants(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  // useEffect
  useEffect(() => {
    getAllOrders(SelectedRestro);
    getAllRestraurants();
  }, [Reload, SelectedRestro]);

  const changeHandler = (e) => {
    if (e.target.value == "All") {
      // setReload(!Reload);
      // return "";
      setSelectedRestro(e.target.value);
    }
    setSelectedRestro(e.target.value);
  };

  console.log("Selected Restro", SelectedRestro);

  return (
    <div>
      {Loading ? (
        <div
          style={{
            width: "100%",
            minHeight: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflowY: "auto",
          }}
          className="col-md-10"
        >
          <Loader />
        </div>
      ) : (
        <>
          <Toaster />
          <br />
          <Select
            style={{ margin: "5px" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            defaultValue="All"
            onChange={changeHandler}
          >
            <MenuItem value="All" selected>
              All
            </MenuItem>
            {Restraurants &&
              Restraurants.map((val) => (
                <MenuItem key={val._id} value={val._id}>
                  {val.restroName}
                </MenuItem>
              ))}
          </Select>
          <Table
            toast={toast}
            setReload={setReload}
            Reload={Reload}
            search={search}
            rows={Orders}
            columns={[
              "OrderId",
              "Order Menu",
              "Order Time",
              "Delivery Type",
              "Order Status",
              "Amount",
            ]}
          />
        </>
      )}
    </div>
  );
};

export default Orders;
