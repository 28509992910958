import React, { useState } from "react";
import ScrollableFeed from "react-scrollable-feed";
import "./ChatSystem.css";
import { db } from "../../config/config";
import firebase from "firebase";

// material-ui
import { Select, MenuItem } from "@material-ui/core";

let unSubscribe = false;
const ChatSystem = ({
  Users,
  setUsers,
  Reload,
  setReload,
  search,
  AllUsers,
}) => {
  // component return
  const [active, setActive] = React.useState(false);
  const [msg, setMessages] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [Load, setLoad] = useState(false);
  const [Filter, setFilter] = useState("");

  React.useEffect(() => {
    if (active) {
      db.collection(`users/${active.userId}/messages`)
        // .orderBy("timeStamp")
        .get()
        .then((querySnapshot) => {
          var arr = [];
          querySnapshot.forEach((doc) => {
            arr.push(doc.data());
          });
          setMessages([...arr]);
        });
    }
  }, [active]);

  React.useEffect(() => {
    if (active) {
      unSubscribe && unSubscribe();

      unSubscribe = db
        .collection(`users/${active.userId}/messages`)
        .orderBy("timeStamp")
        .onSnapshot((querySnapshot) => {
          var arr = [];
          querySnapshot.forEach((doc) => {
            arr.push(doc.data());
          });
          setMessages([...arr]);
        });
    }
  }, [active]);

  const addMessage = () => {
    if (value != "") {
      let messageValue = value;
      setValue("");
      db.collection(`users/${active.userId}/messages`)
        .add({
          name: "Admin",
          timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
          userType: "ADMIN",
          message: messageValue,
        })
        .then((docRef) => {})
        .catch((error) => {
          console.error("Error addding document: ", error);
        });
    }
  };

  //? For Filtering
  React.useEffect(() => {
    console.log("FILTER", Filter);
    if (!Filter) setUsers(AllUsers);

    if (Filter === "all") {
      setUsers(AllUsers);
    }

    if (Filter === true) {
      let activeUsers = AllUsers.filter(
        (user) => user.active && user.active === true
      );
      setUsers(activeUsers);
    }

    if (Filter === false) {
      let closeUsers = AllUsers.filter(
        (user) => !user.active || user.active === false
      );
      setUsers(closeUsers);
    }
  }, [Filter]);

  const filterHandler = (e) => {
    if (e.target.value == "All") {
      setReload(!Reload);
    }

    console.log("SELECTED OPTION", e.target.value);

    setUsers(AllUsers);

    let filterUsers = Users.filter(
      (user) => user.active == Boolean(e.target.value)
    );
    console.log("filterUsers", filterUsers);
    setUsers(filterUsers);

    console.log("AFTER FILTER", Users);
  };

  //* disableChat
  const disableChat = (e) => {
    console.log("VALUE", e.target.value);

    db.collection(`users`).doc(active.userId).set({
      active: e.target.value,
      email: active.email,
      name: active.name,
      userId: active.userId,
    });

    setActive({
      active: e.target.value,
      email: active.email,
      name: active.name,
      userId: active.userId,
    });
  };

  console.log("ACTIVE", active);
  console.log("ALL USERS", AllUsers);
  console.log("USERS", Users);

  return (
    <div style={{ height: "100%" }} className="row p-3 border mx-auto ">
      <div className="col-md-3 p-0">
        <Select
          style={{ width: "100%", marginBottom: "10px" }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          defaultValue={"all"}
          onChange={(e) => setFilter(e.target.value)}
        >
          <MenuItem seleted value="all">
            All Chats
          </MenuItem>
          <MenuItem value={true}>Active Chats</MenuItem>
          <MenuItem value={false}>Closed Chats</MenuItem>
        </Select>

        {Users &&
          Users.filter((user) => {
            if (search && search.toLowerCase() == "") {
              console.log("X", user);
              return user;
            } else if (
              (user.name &&
                user.name.toLowerCase().includes(search.toLowerCase())) ||
              (user.email &&
                user.email.toLowerCase().includes(search.toLowerCase()))
            ) {
              return user;
            }
          }).map((user, index) => (
            <div
              key={index}
              className={
                active.userId == user.userId ? " active_user" : "username-div"
              }
              onClick={() => setActive(user)}
            >
              {user.name}
            </div>
          ))}
      </div>
      <div className="col-md-9">
        <div className="chat-div">
          <div className="chat-header">
            <h3> {active && active.name ? active.name : "No User Selected"}</h3>

            {active && (
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={active.active === true ? true : false}
                onChange={disableChat}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Close</MenuItem>
              </Select>
            )}
          </div>
          {/* <ScrollableFeed> */}
          <div
            className={
              active.active == false ? "close_chat display-div" : "display-div"
            }
          >
            {msg &&
              msg.map((data, index) => {
                if (data.userType == "ADMIN") {
                  return (
                    <p key={index} className="admin-msg">
                      <span className="admin-msg">{data.message}</span>
                    </p>
                  );
                } else {
                  return (
                    <p key={index} className="user-msg">
                      <span>{data.message}</span>
                    </p>
                  );
                }
              })}
          </div>
          {/* </ScrollableFeed> */}
          <div className="send-div-wrapper">
            <>
              <input
                placeholder="type something"
                type="text"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                onKeyDown={(e) => {
                  e.key == "Enter" && addMessage();
                }}
              />

              {active && active.active == true ? (
                <button onClick={addMessage} className="send-btn">
                  Send
                </button>
              ) : null}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatSystem;
