import firebase from "firebase";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAkHCejsyZwZPul0bR6KYe_18jcQ2AP1Mg",
  authDomain: "al-rayhan-c03ae.firebaseapp.com",
  projectId: "al-rayhan-c03ae",
  storageBucket: "al-rayhan-c03ae.appspot.com",
  messagingSenderId: "168963926889",
  appId: "1:168963926889:web:d114948d8df98a4ee1abf8",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

db.settings({ timestampsInSnapshots: true });

export { db };
