import React from "react";
import styles from "./DiscountType.module.css";

import { Radio, TextField, InputAdornment, Checkbox } from "@material-ui/core";
import toast from "react-hot-toast";

const DiscountType = ({ Values, setValues, FormError }) => {
  const {
    discountType,
    discountTypeValue,
    excludeShippingTraffic,
    excludeShippingTrafficValue,
  } = Values;

  console.log("VALUES", Values);

  return (
    <div className={styles.compContent}>
      <h3>Select Discount Type</h3>

      <div className={styles.formGroup}>
        <div className={styles.selectors}>
          <div className={styles.radio}>
            <p>Percentage</p>
            <Radio
              checked={discountType == "PERCENTAGE"}
              onChange={(e) =>
                setValues({
                  ...Values,
                  discountType: "PERCENTAGE",
                  discountTypeValue: "",
                })
              }
            />
          </div>

          <div className={styles.radio}>
            <p>Fixed Value</p>
            <Radio
              checked={discountType == "FIXED-VALUE"}
              onChange={(e) =>
                setValues({
                  ...Values,
                  discountType: "FIXED-VALUE",
                  discountTypeValue: "",
                })
              }
            />
          </div>
        </div>

        {(discountType == "PERCENTAGE" || discountType == "FIXED-VALUE") && (
          <TextField
            type="number"
            label="Discount Type Value"
            variant="outlined"
            value={discountTypeValue}
            error={FormError.discountTypeValue}
            helperText={FormError.discountTypeValue}
            onChange={(e) => {
              // setErr(false)
              if (/^\d+$/.test(e.target.value) || e.target.value == "") {
                discountType == "PERCENTAGE" &&
                  e.target.value <= 100 &&
                  setValues({
                    ...Values,
                    discountTypeValue: e.target.value.replace(/^0+/, ""),
                  });
                discountType == "FIXED-VALUE" &&
                  setValues({
                    ...Values,
                    discountTypeValue: e.target.value.replace(/^0+/, ""),
                  });
              } else {
                toast.error("You Can Only Type in Number!");
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  {discountType == "PERCENTAGE" && "%"}
                  {discountType == "FIXED-VALUE" && "AED"}
                </InputAdornment>
              ),
            }}
          />
        )}
      </div>
    </div>
  );
};

export default DiscountType;
