import React from "react";
import "./OrderStatus.css";

// material-ui
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";

const statuses = [
  { status: "PENDING", name: "PENDING" },
  { status: "APPROVED", name: "APPROVED" },
  { status: "PREPARING", name: "PREPARING" },
  { status: "OUTFORDELIVERY", name: "OUT FOR DELIVERY" },
  { status: "DELIVERED", name: "DELIVERED" },
];

const OrderStatus = ({ currentStatus }) => {
  return (
    <div className="statuses-wrapper">
      {statuses.map((stat, index) => {
        const { status, name } = stat;
        return (
          <div
            className={` ${
              status === currentStatus ? "active-status" : "status"
            }`}
            key={index}
          >
            {status === currentStatus ? (
              <span style={{ wordSpacing: "5px" }}>
                <RadioButtonCheckedIcon /> {name}
              </span>
            ) : (
              <span style={{ wordSpacing: "5px" }}>
                <FiberManualRecordIcon /> {name}
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default OrderStatus;
