import React from "react-dom";

import { TextField, Button } from "@material-ui/core";

const DiscountName = ({ Values, setValues, FormError }) => {
  const { discountName } = Values;

  const generateHandler = () => {
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    let coupon = "";

    for (let i = 0; i < 8; i++) {
      let random = characters.charAt(
        Math.round(Math.random() * characters.length)
      );
      coupon += random;
    }

    console.log("RANDOM", coupon);

    setValues({ ...Values, discountName: coupon });
  };

  return (
    <div
      style={{
        width: "100%",
        marginTop: "10px",
        alignItems: "center",
        display: "flex",
      }}
    >
      <TextField
        style={{ width: "40%" }}
        value={discountName}
        onChange={(e) => setValues({ ...Values, discountName: e.target.value })}
        type="text"
        id="outlined-basic"
        label="Discount Name"
        variant="outlined"
        error={FormError.discountName}
        helperText={FormError.discountName}
      />

      <Button
        className="m-3"
        onClick={generateHandler}
        variant="contained"
        color="primary"
      >
        Generate One
      </Button>
    </div>
  );
};

export default DiscountName;
