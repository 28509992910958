import React, { useState } from "react";
import "./Notifications.css";

import Sidebar from "../Sidebar/Sidebar";
import CancelIcon from "@material-ui/icons/Cancel";
import { IconButton } from "@material-ui/core";

const Notifications = () => {
  const [Count, setCount] = useState([{ notify: "text" }]);
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-2">
          <Sidebar />
        </div>
        <div className="col-md-10">
          {/*  */}
          <div className="notifications-wrapper">
            {Count && Count.length === 0 && (
              <h3 style={{ marginTop: "50px" }}>No Notifications</h3>
            )}
            {Count &&
              Count.map((x) => (
                <div className="notification">
                  <h3>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.....
                  </h3>
                  <IconButton
                    onClick={() => setCount([])}
                    style={{ color: "red" }}
                  >
                    <CancelIcon />
                  </IconButton>
                </div>
              ))}
          </div>
          {/*  */}
        </div>
      </div>
    </div>
  );
};

export default Notifications;
