import React from "react";
import styles from "./Duration.module.css";

import TextField from "@material-ui/core/TextField";

import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";

import moment from "moment";

const Duration = ({ Values, setValues, FormError }) => {
  // useState
  const { startDate, endDate, startTime, endTime } = Values;

  let minDate = new Date().toISOString().slice(0, 10);

  const dateHandler = (name) => (e) => {
    console.log("event in dateHandler", e);
    setValues({ ...Values, [name]: new Date(e).toISOString() });
  };

  const timeHandler = (name) => (e) => {
    console.log("event in timeHandler ", e);
    setValues({ ...Values, [name]: moment(e).format() });
  };

  return (
    <div className={styles.compContent}>
      <h3>Discount Code Duration: </h3>

      <div className={styles.formGroup}>
        <div className={styles.formContent}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Date picker inline"
              value={startDate ? startDate : moment().format()}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              minDate={minDate}
              onChange={dateHandler("startDate")}
            />

            <KeyboardTimePicker
              margin="normal"
              label="Start Time"
              id="time-picker"
              value={startDate ? startDate : moment(startTime).format()}
              onChange={timeHandler("startTime")}
              KeyboardButtonProps={{
                "aria-label": "change time",
              }}
            />
          </MuiPickersUtilsProvider>
          {/* <TextField
            type="time"
            label="Start Time"
            variant="outlined"
            InputLabelProps={{ shrink: true, required: true }}
            InputProps={{ inputProps: { min: minTime } }}
            value={startTime}
            mindate={minTime}
            onChange={(e) => {
              console.log("startTime", e);
              setValues({ ...Values, startTime: e.target.value });
            }}
            className={styles.textField}
            error={FormError.startTime}
            helperText={FormError.startTime}
          /> */}
        </div>

        <div className={styles.formContent}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {/* <KeyboardDatePicker
              label="End Date"
              variant="inline"
              autoOk={true}
              InputLabelProps={{ shrink: true, required: true }}
              InputProps={{ inputProps: { min: startDate } }}
              value={endDate}
              format="yyyy-MM-dd"
              minDate={startDate}
              onChange={(date) =>
                setValues({
                  ...Values,
                  endDate: moment(date).format("yyyy-MM-DD"),
                })
              }
              inputVariant="outlined"
              className={styles.textField}
            /> */}

            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Date picker inline"
              value={
                endDate
                  ? moment(endDate).format()
                  : new moment().add(1, "day")._d
              }
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              minDate={startDate ? moment(startDate).toDate() : minDate}
              onChange={dateHandler("endDate")}
            />

            <KeyboardTimePicker
              margin="normal"
              id="time-picker"
              label="End Time"
              value={endDate ? endDate : moment(endTime).format()}
              onChange={timeHandler("endTime")}
              KeyboardButtonProps={{
                "aria-label": "change time",
              }}
            />
          </MuiPickersUtilsProvider>

          {/* <TextField
            type="time"
            label="End time"
            variant="outlined"
            InputLabelProps={{ shrink: true, required: true }}
            value={endTime}
            onChange={(e) => setValues({ ...Values, endTime: e.target.value })}
            className={styles.textField}
          /> */}
        </div>
      </div>
    </div>
  );
};
export default Duration;
