import React, { useState, useEffect } from "react";

import {
  TextField,
  Select,
  Button,
  InputLabel,
  MenuItem,
} from "@material-ui/core";

// components
import Loader from "../../Loader/Loader";
import toast, { Toaster } from "react-hot-toast";

// helpers
import { createItemForCategory } from "../../helpers/itemHelper";
import { useParams, useHistory } from "react-router-dom";

const ProductDetailsForCategory = ({ CurrentPage, setCurrentPage }) => {
  const [Loading, setLoading] = useState(false);
  const [Reload, setReload] = useState(false);
  const [Values, setValues] = useState({
    name: "",
    status: true,
    amount: "",
    description: "",
    photo: "",
    nameArabic: "",
    descriptionArabic: "",
    formData: new FormData(),
  });

  let { categoryId } = useParams();

  let history = useHistory();

  // destructuring
  const {
    name,
    status,
    amount,
    description,
    photo,
    formData,
    nameArabic,
    descriptionArabic,
  } = Values;

  // changeHandler
  const changeHandler = (name) => (e) => {
    let value = name === "photo" ? e.target.files[0] : e.target.value.trim();

    formData.set(name, value);
    setValues({ ...Values, [name]: value });
  };

  // submitHandler
  const submitHandler = (e) => {
    e.preventDefault();

    setLoading(true);

    createItemForCategory(categoryId, formData)
      .then((data) => {
        if (data.error) {
          setLoading(false);
          toast.error(data.message);
          return "";
        }
        console.log("CREATE ITEM RESPONSE", data);
        setValues({
          name: "",
          status: true,
          amount: "",
          description: "",
          photo: "",
          category: "",
          formData: "",
        });
        localStorage.setItem("itemId", JSON.stringify(data.createdItem));
        setReload(!Reload);
        setLoading(false);
        toast.success("Item Added!");
        setCurrentPage("Attributes");
      })
      .catch((err) => {
        console.error(err);

        setLoading(true);
      });
  };

  console.log(Values);

  // component return
  return (
    <div>
      {Loading ? (
        <div
          style={{
            width: "100%",
            minHeight: "60vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="col-md-10"
        >
          <Loader />
        </div>
      ) : (
        <div>
          <Toaster />
          <form
            onSubmit={submitHandler}
            style={{
              width: "100%",
              margin: "auto",
              boxShadow: "0 0 4px rgba(0,0,0,0.4)",
              padding: "10px",
              marginTop: "10px",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <TextField
                style={{ width: "60%" }}
                id="standard-basic"
                label="Product Name"
                defaultValue={name}
                onChange={changeHandler("name")}
              />

              <TextField
                style={{ width: "60%", marginLeft: "20px" }}
                id="standard-basic"
                label="Short Description"
                defaultValue={description}
                onChange={changeHandler("description")}
              />
            </div>
            <br />
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <TextField
                style={{ width: "60%" }}
                id="standard-basic"
                label="Product Name (in arabic)"
                defaultValue={nameArabic}
                onChange={changeHandler("nameArabic")}
              />

              <TextField
                style={{ width: "60%", marginLeft: "20px" }}
                id="standard-basic"
                label="Short Description (in arabic)"
                defaultValue={descriptionArabic}
                onChange={changeHandler("descriptionArabic")}
              />
            </div>
            <br />
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                {/* Status */}
                <div>
                  <InputLabel htmlFor="age-native-simple">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    defaultValue={status}
                    onChange={changeHandler("status")}
                  >
                    <MenuItem value="true">True</MenuItem>
                    <MenuItem value="false">False</MenuItem>
                  </Select>
                </div>

                <Button variant="contained" component="label">
                  {photo ? `${photo.name}` : "Upload Image"}
                  <input onChange={changeHandler("photo")} type="file" hidden />
                </Button>
                <br />
              </div>

              <TextField
                type="number"
                style={{ width: "50%", marginLeft: "20px" }}
                id="standard-basic"
                label="Amount"
                defaultValue={amount}
                onChange={changeHandler("amount")}
              />
            </div>
            <br />
            {/* Submit Button */}
            <div className="text-center mt-3">
              <button
                type="submit"
                style={{
                  padding: "15px",
                  width: "30%",
                  height: "fit-content",
                  backgroundColor: "black",
                  color: "white",
                  border: "none",
                  position: "absolute",
                  left: "42.57%",
                  right: "23.54%",
                  top: "80.55%",
                  bottom: "3.92%",

                  background: "#00A0A7",
                  borderRadius: "5px",
                }}
              >
                Save Changes and Next
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default ProductDetailsForCategory;
