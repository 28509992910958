import React, { useState } from "react";

import { Redirect } from "react-router";

// components
import Loader from "../../Loader/Loader";

// material-ui
import { Button, FormHelperText } from "@material-ui/core";

// helpers
import { addRestraurant } from "../../helpers/restraurantHelper";

// notifications
import { toast, Toaster } from "react-hot-toast";

const RestraurantDetails = ({ CurrentPage, setCurrentPage }) => {
  // useState hooks
  const [Loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [FormError, setFormError] = useState({
    restroName: false,
    slogan: false,
    description: false,
    address: false,
    city: false,
    zip: false,
    province: false,
    code: false,
    photo: false,
    deliveryRadius: false,
  });
  const [Values, setValues] = useState({
    restroName: "",
    slogan: "",
    description: "",
    address: "",
    city: "",
    zip: "",
    province: "",
    code: "",
    photo: "",
    deliveryRadius: "",
    formData: new FormData(),
  });

  // destructuring
  const {
    restroName,
    slogan,
    description,
    address,
    code,
    city,
    zip,
    province,
    photo,
    deliveryRadius,
    formData,
  } = Values;

  // changeHandler
  const changeHandler = (name) => (e) => {
    let value = name === "photo" ? e.target.files[0] : e.target.value;
    formData.set(name, value);
    setValues({ ...Values, [name]: value });
  };

  // submitHandler
  const submitHandler = (e) => {
    e.preventDefault();

    if (Validate()) {
      setLoading(true);

      addRestraurant(formData)
        .then((data) => {
          if (data.error) {
            toast.error(data.message);
            setLoading(false);
            return "";
          }
          console.log("DATA", data);
          setValues({
            restroName: "",
            slogan: "",
            description: "",
            address: "",
            city: "",
            zip: "",
            province: "",
            code: "",
            photo: "",
            formData: new FormData(),
          });
          localStorage.setItem("RestroId", JSON.stringify(data.data));
          setLoading(false);
          setRedirect(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const Validate = () => {
    let value = true;
    let err = {
      restroName: false,
      slogan: false,
      description: false,
      address: false,
      city: false,
      zip: false,
      province: false,
      code: false,
      photo: false,
      deliveryRadius: false,
    };

    if (restroName == "") {
      value = false;
      err.restroName = "Enter Restaurant Name First!";
    }

    // if (slogan == "") {
    //   value = false;
    //   err.slogan = "Enter Slogan First!";
    // }

    if (address == "") {
      value = false;
      err.address = "Enter Address First!";
    }

    // if (code == "") {
    //   value = false;
    //   err.code = "Enter Tracking Code First!";
    // } else if (code < 0) {
    //   value = false;
    //   err.code = "Please Enter Valid Tracking Code First!";
    // }

    if (city == "") {
      value = false;
      err.city = "Enter City First!";
    }

    if (province == "") {
      value = false;
      err.province = "Enter Province First!";
    }

    if (zip == "") {
      value = false;
      err.zip = "Enter Zip First!";
    } else if (zip < 0) {
      value = false;
      err.zip = "Enter Valid Zip First!";
    }

    if (deliveryRadius == "") {
      value = false;
      err.deliveryRadius = "Enter Delivery Radius First!";
    } else if (deliveryRadius < 0) {
      value = false;
      err.deliveryRadius = "Enter Valid Delivery Radius First!";
    }

    // if (description == "") {
    //   value = false;
    //   err.description = "Enter Description First!";
    // }

    if (photo == "") {
      value = false;
      err.photo = "Select a Photo First!";
    }

    setFormError({ ...err });
    return value;
  };

  const redirectHandler = () => {
    if (redirect) {
      return <Redirect to="/admin/dashboard/setting" />;
    }
  };

  console.log("VALUES", Values);
  console.log("FormError", FormError);

  // component return
  return (
    <div>
      {Loading ? (
        <div
          style={{
            width: "100%",
            minHeight: "60vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="col-md-10"
        >
          <Loader />
        </div>
      ) : (
        <>
          <Toaster />
          {redirectHandler()}
          <div
            style={{
              boxShadow: "0 0 4px rgba(0,0,0,0.4)",
              padding: "10px",
              margin: "10px 0px 20px 0px",
            }}
          >
            <form onSubmit={submitHandler} className="m-3 mx-auto">
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <input
                  style={{
                    borderRadius: "5px",
                    width: "98%",
                    border: "1px solid #888888",
                    outline: "none",
                  }}
                  className={
                    FormError.restroName ? "border border-danger p-3" : "p-3"
                  }
                  placeholder={
                    FormError.restroName
                      ? FormError.restroName
                      : "Restaurant Name"
                  }
                  type="text"
                  value={restroName}
                  onChange={changeHandler("restroName")}
                />
                {/* <input
                  style={{
                    borderRadius: "5px",
                    width: "45%",
                    border: "1px solid #888888",
                    outline: "none",
                  }}
                  className={
                    FormError.slogan ? "border border-danger p-3" : "p-3"
                  }
                  placeholder={FormError.slogan ? FormError.slogan : "Slogan"}
                  type="text"
                  value={slogan}
                  onChange={changeHandler("slogan")}
                /> */}
              </div>
              {/* <textarea
                style={{
                  width: "99%",
                  padding: "10px",
                  margin: "10px",
                  borderRadius: "5px",
                  outline: "none",
                }}
                name=""
                id=""
                cols="30"
                rows="5"
                placeholder={
                  FormError.description ? FormError.description : "Description"
                }
                className={
                  FormError.description ? "border border-danger mt-3" : "mt-3"
                }
                // className=""
                value={description}
                onChange={changeHandler("description")}
              /> */}
              <br />
              <br />
              <Button
                style={{ marginLeft: "10px" }}
                variant="contained"
                component="label"
              >
                Upload Restro Photo
                <input onChange={changeHandler("photo")} type="file" hidden />
              </Button>
              {photo ? (
                <>
                  <br />
                  <br />
                  <span className="badge bg-primary"> {photo.name}</span>
                  <br />
                </>
              ) : (
                ""
              )}

              {FormError.photo && (
                <FormHelperText style={{ color: "red", marginLeft: "15px" }}>
                  <p>{FormError.photo}</p>
                </FormHelperText>
              )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                className="mt-3"
              >
                <h4 style={{ marginLeft: "10px" }}>Address</h4>
              </div>
              <input
                placeholder={FormError.address ? FormError.address : "Address"}
                style={{
                  width: "100%",
                  marginTop: "15px",
                  padding: "15px",
                  outline: "none",
                  borderRadius: "5px",
                  border: "1px solid #888888",
                }}
                className={FormError.address ? "border border-danger" : ""}
                type="text"
                value={address}
                onChange={changeHandler("address")}
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-evenly",
                  marginTop: "20px",
                }}
              >
                <div style={{ margin: "auto" }} className="col-sm-4">
                  <input
                    style={{
                      width: "90%",
                      padding: "15px",
                      border: "1px solid #888888",
                      borderRadius: "5px",
                      outline: "none",
                    }}
                    placeholder={FormError.city ? FormError.city : "City"}
                    className={FormError.city ? "border border-danger" : ""}
                    type="text"
                    value={city}
                    onChange={changeHandler("city")}
                  />
                </div>

                <div style={{ margin: "auto" }} className="col-sm-4">
                  <input
                    style={{
                      width: "90%",
                      padding: "15px",
                      border: "1px solid #888888",
                      borderRadius: "5px",
                      outline: "none",
                    }}
                    placeholder={
                      FormError.province ? FormError.province : "Province"
                    }
                    className={FormError.province ? "border border-danger" : ""}
                    type="text"
                    value={province}
                    onChange={changeHandler("province")}
                  />
                </div>

                <div style={{ margin: "auto" }} className="col-sm-4">
                  <input
                    style={{
                      width: "90%",
                      padding: "15px",
                      border: "1px solid #888888",
                      borderRadius: "5px",
                      outline: "none",
                    }}
                    placeholder={FormError.zip ? FormError.zip : "Zip"}
                    className={FormError.zip ? "border border-danger" : ""}
                    type="number"
                    value={zip}
                    onChange={changeHandler("zip")}
                  />
                  <FormHelperText error={FormError.zip}>
                    {FormError.zip}
                  </FormHelperText>
                </div>
              </div>

              <div
                style={{ width: "100%", display: "flex", marginTop: "20px" }}
              >
                <div className="col-sm-4">
                  <input
                    style={{
                      width: "90%",
                      padding: "15px",
                      border: "1px solid #888888",
                      borderRadius: "5px",
                      outline: "none",
                      WebkitAppearance: "none",
                      MozAppearance: "textfield",
                    }}
                    className={
                      FormError.deliveryRadius ? "border border-danger" : ""
                    }
                    placeholder={
                      FormError.deliveryRadius
                        ? FormError.deliveryRadius
                        : "Delivery Radius"
                    }
                    type="number"
                    value={deliveryRadius}
                    onChange={changeHandler("deliveryRadius")}
                  />
                  <FormHelperText error={FormError.deliveryRadius}>
                    {FormError.deliveryRadius}
                  </FormHelperText>
                </div>

                {/* <div className="col-sm-4">
                  <input
                    placeholder={
                      FormError.code ? FormError.code : "Tracking Code"
                    }
                    className={FormError.code ? "border border-danger" : ""}
                    style={{
                      width: "90%",
                      padding: "15px",
                      border: "1px solid #888888",
                      borderRadius: "5px",
                      outline: "none",
                    }}
                    type="number"
                    value={code}
                    onChange={changeHandler("code")}
                  />
                  <FormHelperText error={FormError.code}>
                    {FormError.code}
                  </FormHelperText>
                </div> */}
              </div>
              <div className="text-center mt-3">
                <button
                  // style={{
                  //   padding: "10px",
                  //   width: "40%",
                  //   margin: "auto",
                  //   backgroundColor: "black",
                  //   color: "white",
                  //   border: "none",
                  // }}
                  style={{
                    padding: "25px",
                    width: "30%",
                    margin: "40px auto",
                    backgroundColor: "black",
                    color: "white",
                    border: "none",
                    // position: "absolute",
                    left: "42.57%",
                    right: "23.54%",
                    top: "80.55%",
                    bottom: "3.92%",

                    background: "#00A0A7",
                    borderRadius: "5px",
                  }}
                >
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </>
      )}
    </div>
  );
};

export default RestraurantDetails;
