import React, { useState } from "react";

// components
import Sidebar from "../Sidebar/Sidebar";
import SettingTabs from "../Tabs/SettingTabs";
import Header from "../header/Header";

const dummyData = [
  { name: "Restaurant Details" },
  // { name: "Schedule" },
  // { name: "Timing" },
];

const Setting = () => {
  const [CurrentPage, setCurrentPage] = useState("Restaurant Details");

  return (
    <div className="container-fluid">
      <div className="row">
        <div style={{ padding: "0" }} className="col-md-2">
          <Sidebar />
        </div>
        <div className="col-md-10">
          <Header />
          <SettingTabs
            CurrentPage={CurrentPage}
            setCurrentPage={setCurrentPage}
            Pages={dummyData}
          />
        </div>
      </div>
    </div>
  );
};

export default Setting;
