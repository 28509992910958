import React, { useState } from "react";
import styles from "./Requirements.module.css";

import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";

const Requirements = ({ Values, setValues }) => {
  const [err, setErr] = useState({
    minimumRequirementValue: false,
  });

  const { minimumRequirement, minimumRequirementValue } = Values;

  return (
    <div className={styles.compContent}>
      <h3>Minimum Requirements: </h3>

      <div className={styles.formGroup}>
        <div className={styles.selectors}>
          <div className={styles.radio}>
            <p>No Requirements</p>
            <Radio
              checked={minimumRequirement == "NO-REQUIREMENT"}
              onChange={(e) =>
                setValues({
                  ...Values,
                  minimumRequirement: "NO-REQUIREMENT",
                  minimumRequirementValue: 0,
                })
              }
            />
          </div>

          <div className={styles.radio}>
            <p>Minimum Purchase Amount</p>
            <Radio
              checked={minimumRequirement == "MINIMUM-PURCHASE-AMOUNT"}
              onChange={(e) =>
                setValues({
                  ...Values,
                  minimumRequirement: "MINIMUM-PURCHASE-AMOUNT",
                })
              }
            />
          </div>

          {/* <div className={styles.radio}>
            <p>Minimum Number</p>
            <Radio
              checked={minimumRequirement == "MINIMUM-NUMBER"}
              onChange={(e) =>
                setValues({
                  ...Values,
                  minimumRequirement: "MINIMUM-NUMBER",
                })
              }
            />
          </div> */}
        </div>

        {minimumRequirement != "NO-REQUIREMENT" && (
          <TextField
            type="number"
            label="Minimum requirement Value"
            variant="outlined"
            value={minimumRequirementValue}
            onChange={(e) =>
              setValues({ ...Values, minimumRequirementValue: e.target.value })
            }
            className={styles.textField}
          />
        )}
      </div>
    </div>
  );
};
export default Requirements;
