import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Modal from "react-modal";

import "./SubCategories.css";

// react-router-dom
import { useHistory } from "react-router-dom";

// components
import Sidebar from "../Sidebar/Sidebar";
import Loader from "../../Loader/Loader";
import AddModal from "../Modal/Modal";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Header from "../header/Header";

// material-ui
import {
  TextField,
  Button,
  Select,
  InputLabel,
  MenuItem,
} from "@material-ui/core";

import SubCategoryList from "../SubCategoryList/SubCategoryList";
import Items from "../Items/CategoryItems";

// helpers
import {
  getSubCategories,
  createSubCategory,
  updateSubCategory,
  deleteSubCategory,
} from "../../helpers/categoryHelper";
import { getRestraurants } from "../../helpers/restraurantHelper";

// Toast
import toast, { Toaster } from "react-hot-toast";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    width: "30%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

const Pages = [{ name: "Sub Categories" }, { name: "Items" }];

const SubCategories = () => {
  //   param extraction
  const { categoryId } = useParams();

  // useState hooks
  const [CurrentPage, setCurrentPage] = useState("Sub Categories");
  const [Loading, setLoading] = useState(true);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [Restros, setRestros] = useState(false);
  const [EditModalOpen, setEditModalOpen] = useState(false);
  const [Edit, setEdit] = useState("");
  const [search, setSearch] = useState("");
  const [Reload, setReload] = useState(false);
  const [SubCategories, setSubCategories] = useState([]);
  const [Categories, setCategories] = useState([]);
  const [Values, setValues] = useState({
    subCategoryName: "",
    category: categoryId,
    active: true,
    restro: "",
    photo: "",
    arabicSubCategoryName: "",
    formData: "",
  });
  const [EditValues, setEditValues] = useState({
    subCategoryName: "",
    category: categoryId,
    active: "",
    restro: "",
    photo: "",
    arabicSubCategoryName: "",
    formData: "",
  });

  //   destructuring
  const {
    active,
    category,
    subCategoryName,
    photo,
    arabicSubCategoryName,
    formData,
    restro,
  } = Values;

  const history = useHistory();

  //   useEffect
  useEffect(() => {
    setLoading(true);

    const getAllRestaurants = () => {
      getRestraurants()
        .then((data) => {
          console.log("RESTAURANTS", data);
          setRestros(data);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    getAllRestaurants();

    getSubCategories(categoryId)
      .then((data) => {
        console.log("SUB CATEGORIES", data);
        setSubCategories(data);
        setValues({ ...Values, formData: new FormData() });
        setLoading(false);
      })
      .catch((err) => console.error(err));
  }, [Reload]);

  // changeHandler
  const changeHandler = (name) => (e) => {
    let value = name === "photo" ? e.target.files[0] : e.target.value.trim();
    formData.set(name, value);
    setValues({ ...Values, [name]: value });
  };

  // addHandler
  const addHandler = (e) => {
    e.preventDefault();

    formData.append("category", categoryId);

    setLoading(true);

    if (!subCategoryName) {
      toast.error("Please Include All Fields First!");
      setLoading(false);
      return "";
    }

    createSubCategory(formData)
      .then((data) => {
        if (data.error) {
          setLoading(false);
          toast.error(data.message);
          return;
        }
        console.log("ADD SUB CATEGORY RESPONSE", data);
        setReload(!Reload);
        setIsOpen(false);
        setValues({
          subCategoryName: "",
          category: categoryId,
          active: true,
          restro: "",
          photo: "",
          arabicSubCategoryName: "",
          formData: new FormData(),
        });
        toast.success(data.message);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  // changeEditHandler
  const changeEditHandler = (name) => (e) => {
    const { formData } = EditValues;

    let value = name === "photo" ? e.target.files[0] : e.target.value;
    formData.set(name, value);
    setEditValues({ ...EditValues, [name]: value });
  };

  // editHandler
  const editHandler = (e) => {
    e.preventDefault();

    setLoading(true);
    const { formData } = EditValues;

    let _id = Edit._id;
    updateSubCategory(_id, formData)
      .then((data) => {
        if (data.error) {
          setLoading(false);
          toast.error(data.message);
          return "";
        }
        console.log("UPDATED SUB CATEGORY", data);
        setEditModalOpen(false);
        setReload(!Reload);
        setLoading(false);
        toast.success(data.message);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  //   deleteHandler
  const deleteHandler = (_id) => {
    setLoading(true);

    deleteSubCategory(_id)
      .then((data) => {
        setReload(!Reload);
        setLoading(false);
        toast.success(data.message);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  // modal functions
  const closeModal = () => {
    setIsOpen(false);
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
  };

  //
  console.log("CATEGORY ID", categoryId);
  console.log("VALUES", Values);
  console.log("EDIT VALUES", EditValues);
  console.log("EDIT", Edit);

  //   component return
  return (
    <div className="container-fluid">
      <div className="row">
        <div style={{ padding: "0" }} className="col-md-2">
          <Sidebar />
        </div>
        {Loading ? (
          <div
            className="col-md-10"
            style={{
              height: "90vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader />
          </div>
        ) : (
          <div className="col-md-10">
            <Header search={search} setSearch={setSearch} />
            <Toaster />

            {/* TOP BAR */}
            <div className="top-bar">
              <h3
                style={{ cursor: "pointer" }}
                onClick={() => history.goBack()}
                className="back"
              >
                <ArrowBackIosIcon />
                Back to Categories
              </h3>
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {CurrentPage == "Items" && (
                  <button
                    onClick={() =>
                      history.push(
                        `/admin/dashboard/menu/category/${categoryId}/create/items`
                      )
                    }
                    style={{ width: "auto" }}
                    className="user-btn"
                  >
                    Add New Item
                  </button>
                )}

                {CurrentPage == "Sub Categories" && (
                  <button
                    onClick={() => setIsOpen(true)}
                    style={{ width: "auto" }}
                    className="user-btn"
                  >
                    Add Sub Category
                  </button>
                )}
              </div>
            </div>
            {/* TOP BAR END */}

            <AddModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen}>
              <form onSubmit={addHandler}>
                <h3 style={{ textAlign: "center" }}>Add Sub Category</h3>
                <hr />

                <TextField
                  style={{ width: "100%" }}
                  id="standard-basic"
                  label="Subcategory Name"
                  defaultValue={subCategoryName}
                  onChange={changeHandler("subCategoryName")}
                />
                <br />
                <br />

                <TextField
                  style={{ width: "100%" }}
                  id="standard-basic"
                  label="Subcategory Name (in arabic)"
                  defaultValue={arabicSubCategoryName}
                  onChange={changeHandler("arabicSubCategoryName")}
                />
                <br />
                <br />

                <Button variant="contained" component="label">
                  Upload Image
                  <input onChange={changeHandler("photo")} type="file" hidden />
                </Button>
                {photo ? (
                  <>
                    <br />
                    <br />
                    <span className="badge bg-primary"> {photo.name}</span>
                  </>
                ) : (
                  ""
                )}
                <br />
                <br />

                <InputLabel id="label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  defaultValue={active}
                  onChange={changeHandler("active")}
                >
                  <MenuItem value="true">True</MenuItem>
                  <MenuItem value="false">False</MenuItem>
                </Select>
                <br />
                <br />

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button type="submit" variant="contained" color="primary">
                    Add SubCategory
                  </Button>

                  <Button
                    onClick={closeModal}
                    variant="contained"
                    color="secondary"
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </AddModal>

            {Edit && (
              <Modal
                isOpen={EditModalOpen}
                onRequestClose={closeEditModal}
                style={customStyles}
                contentLabel="Example Modal"
              >
                <form onSubmit={editHandler}>
                  <h3 style={{ textAlign: "center" }}>Edit SubCategory</h3>
                  <hr />

                  <TextField
                    style={{ width: "100%" }}
                    id="standard-basic"
                    label="Name"
                    defaultValue={Edit.subCategoryName}
                    onChange={changeEditHandler("subCategoryName")}
                  />
                  <br />
                  <br />
                  <TextField
                    style={{ width: "100%" }}
                    id="standard-basic"
                    label="Subcategory Name (in arabic)"
                    defaultValue={Edit.arabicSubCategoryName}
                    onChange={changeEditHandler("arabicSubCategoryName")}
                  />
                  <br />
                  <br />

                  <Button variant="contained" component="label">
                    Upload Image
                    <input
                      onChange={changeEditHandler("photo")}
                      type="file"
                      hidden
                    />
                  </Button>
                  <br />
                  <br />

                  <InputLabel id="label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    defaultValue={Edit.active}
                    onChange={(e) =>
                      setEditValues({
                        ...EditValues,
                        active: e.target.value.trim(),
                      })
                    }
                  >
                    <MenuItem value="true">True</MenuItem>
                    <MenuItem value="false">False</MenuItem>
                  </Select>
                  <br />
                  <br />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button type="submit" variant="contained" color="primary">
                      Edit SubCategory
                    </Button>

                    <Button
                      onClick={closeEditModal}
                      variant="contained"
                      color="secondary"
                    >
                      Cancel
                    </Button>
                  </div>
                </form>
              </Modal>
            )}

            <div
              style={{
                width: "100%",
                padding: "10px",
                margin: "10px auto",
                display: "flex",
                justifyContent: "space-around",
                boxShadow: "0 0 4px rgba(0,0,0,0.4)",
              }}
            >
              {Pages &&
                Pages.map((page, index) => {
                  return (
                    <h3
                      className={
                        CurrentPage === page.name && "active_subcategory_page"
                      }
                      style={{ cursor: "pointer" }}
                      onClick={() => setCurrentPage(page.name)}
                      key={index}
                    >
                      {page.name}
                    </h3>
                  );
                })}
            </div>
            <div>
              {CurrentPage == "Sub Categories" && (
                <SubCategoryList
                  Edit={(data) => {
                    setEdit(data);
                    setEditValues({
                      subCategoryName: data.subCategoryName,
                      active: data.active,
                      category: data.category,
                      arabicSubCategoryName: data.arabicSubCategoryName,
                      formData: new FormData(),
                      restro: data.restro,
                    });
                    setEditModalOpen(true);
                  }}
                  Delete={(_id) => deleteHandler(_id)}
                  search={search}
                  SubCategories={SubCategories}
                />
              )}

              {CurrentPage == "Items" && <Items search={search} />}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SubCategories;
