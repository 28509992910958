import React, { useState } from "react";

import AddIcon from "@material-ui/icons/Add";
import AddModal from "../Modal/Modal";

import { Button, TextField, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

// helpers
import {
  createExtra,
  deleteExtra,
  updateExtra,
} from "../../helpers/itemHelper";
import toast from "react-hot-toast";

const data = [{ dummy: 1 }, { dummy: 1 }];

const Extras = ({ ExtrasData, setLoading, Reload, setReload }) => {
  // useState hooks
  const [modalIsOpen, setIsOpen] = useState(false);
  const [Values, setValues] = useState({
    extraName: "",
    extraPrice: "",
    extraNameArabicName: "",
  });

  // destructuring
  const { extraName, extraPrice, extraNameArabicName } = Values;

  // changeHandler
  const changeHandler = (name) => (e) => {
    let value = e.target.value.trim();

    setValues({ ...Values, [name]: value });
  };

  // addHandler
  const addHandler = (e) => {
    e.preventDefault();

    setLoading(true);

    if (!localStorage.getItem("itemId")) {
      setLoading(false);
      toast.error("Please make one item first!");
      return;
    }

    let item = JSON.parse(localStorage.getItem("itemId"));
    let { _id } = item;

    createExtra(_id, Values)
      .then((data) => {
        if (data.error) {
          setLoading(false);
          toast.error(data.message);
          return "";
        }
        console.log("EXTRA", data);
        setReload(!Reload);
        setLoading(false);
        toast.success(data.message);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error("Something went wrong!");
      });
  };

  // updateHandler

  // deleteHandler
  const deleteHandler = (extraId) => {
    let confirm = window.confirm("Do you really want to delete this?");

    if (confirm) {
      let item = JSON.parse(localStorage.getItem("itemId"));
      let { _id } = item;

      deleteExtra(_id, extraId)
        .then((data) => {
          if (data.error) {
            setLoading(false);
            toast.error(data.message);
            return;
          }
          console.log(data);
          setIsOpen(false);
          setReload(!Reload);
          setLoading(false);
          toast.success(data.message);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  console.log("EXTRAS", ExtrasData);

  // component return
  return (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3,1fr)",
          gridGap: "30px",
        }}
      >
        {ExtrasData &&
          ExtrasData.map((data, indx) => {
            const { extraName, extraPrice, _id } = data;
            return (
              <div
                key={indx}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  boxShadow: "0 0 4px rgba(0,0,0,0.4)",
                  padding: "10px",
                }}
              >
                <h3>
                  {extraName} (AED {extraPrice})
                </h3>
                <div>
                  <IconButton color="primary">
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => deleteHandler(_id)}
                    color="secondary"
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </div>
            );
          })}

        {/* ADD EXTRA MODAL */}
        <AddModal setIsOpen={setIsOpen} modalIsOpen={modalIsOpen}>
          <form onSubmit={addHandler}>
            <h3 style={{ textAlign: "center" }}>Add Extras</h3>
            <hr />
            <TextField
              style={{ width: "100%" }}
              id="standard-basic"
              label="Extra Name"
              defaultValue={extraName}
              onChange={changeHandler("extraName")}
            />
            <br />

            <TextField
              style={{ width: "100%" }}
              id="standard-basic"
              label="Extra Name (in arabic)"
              defaultValue={extraNameArabicName}
              onChange={changeHandler("extraNameArabicName")}
            />
            <br />
            <TextField
              type="number"
              style={{ width: "100%" }}
              id="standard-basic"
              label="Price (AED)"
              defaultValue={extraPrice}
              onChange={changeHandler("extraPrice")}
            />
            <br />
            <br />

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button type="submit" variant="contained" color="primary">
                Add Extra
              </Button>

              <Button
                onClick={() => setIsOpen(false)}
                variant="contained"
                color="secondary"
              >
                Cancel
              </Button>
            </div>
          </form>
        </AddModal>
        {/* ADD EXTRA MODAL END*/}

        {/* ADD EXTRA BUTTON */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
            boxShadow: "0 0 4px rgba(0,0,0,0.4)",
          }}
        >
          <Button
            onClick={() => setIsOpen(true)}
            variant="contained"
            color="primary"
          >
            <AddIcon />
            Add Extras
          </Button>
        </div>
        {/*  */}
      </div>
    </>
  );
};

export default Extras;
