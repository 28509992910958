import { API } from "../Backend";

// getAdminUsers
export const getAdminUsers = () => {
  return fetch(`${API}/api/admin/get/adminUsers`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

// addNewUser
export const addNewUser = (data) => {
  return fetch(`${API}/api/admin/add/new/adminUser`, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: data,
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

// updateUser
export const updateUser = (id, data) => {
  console.log("UPDATE USER DATA", data);

  return fetch(`${API}/api/adminUser/${id}/update`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
    },
    body: data,
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

// deleteUser
export const deleteUser = (id) => {
  return fetch(`${API}/api/adminUser/${id}/delete`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

export const getAdminDashboard = () => {
  return fetch(`${API}/api/admin/get/dashboard`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};
