import React from "react";
import "./Tabs.css";

// components
import Orders from "../Order/Orders";
import PendingOrders from "../Order/PendingOrders";
import OrderHistory from "../Order/OrderHistory";

const Tabs = ({ CurrentPage, setCurrentPage, Pages, search, setSearch }) => {
  const currentTab = (name) => {
    if (name == CurrentPage) {
      return "tab-active";
    } else {
      return "";
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          padding: "10px 15px",
          boxShadow: "0 0 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          background: "white",
        }}
      >
        {Pages.map((page) => {
          const { name, index } = page;
          return (
            <div key={index}>
              <h3
                style={{ paddingBottom: "15px", fontSize: "20px" }}
                className={currentTab(name)}
                onClick={() => {
                  setCurrentPage(name);
                }}
              >
                {name}
              </h3>
            </div>
          );
        })}
      </div>
      {CurrentPage === "Orders" && (
        <Orders search={search} setSearch={setSearch} />
      )}
      {CurrentPage === "Pending Orders" && (
        <PendingOrders search={search} setSearch={setSearch} />
      )}
      {/* {CurrentPage === "History" && (
        <OrderHistory search={search} setSearch={setSearch} />
      )} */}
    </>
  );
};

export default Tabs;
