import { API } from "../Backend";

// getRestraurantOrders
export const getRestraurantOrders = (restroId) => {
  return fetch(`${API}/api/admin/restraurant/${restroId}/orders`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

// getAllOrders
export const getAllOrders = (startDate, endDate) => {
  return fetch(
    `${API}/api/admin/get/orders/start/${startDate}/end/${endDate}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    }
  )
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

export const getOrders = (restroId) => {
  console.log(
    "API",
    `${API}/api/admin/get/orders${
      restroId && restroId != "All" ? "?restroId=" + restroId : ""
    }`
  );
  return fetch(
    `${API}/api/admin/get/orders${
      restroId != "All" ? "?restroId=" + restroId : ""
    }`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    }
  )
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

// getOrderRequests
export const getOrderRequests = () => {
  return fetch(`${API}/api/admin/get/order/requests`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

// getPendingOrders
export const getPendingOrders = (restroId) => {
  return fetch(
    `${API}/api/admin/get/pending/orders${
      restroId && restroId != "All" ? "?restroId=" + restroId : ""
    }`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    }
  )
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

// getCompletedOrders
export const getCompletedOrders = () => {
  return fetch(`${API}/api/admin/get/completed/orders`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

export const acceptOrRejectOrder = (adminId, orderId, data) => {
  console.log("isAcceped", data);

  return fetch(
    `${API}/api/admin/${adminId}/accept/or/reject/order/${orderId}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  )
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

// getOrderDetails
export const getOrderDetails = (orderId) => {
  return fetch(`${API}/api/user/order/${orderId}/get/details`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

// updateOrderstatus
export const updateOrderStatus = (orderId, status) => {
  return fetch(`${API}/api/admin/update/order/${orderId}/status`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ status: status }),
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};
