import React, { useState, useEffect } from "react";
import { db } from "../../config/config";

// components
import Loader from "../../Loader/Loader";
import Header from "../header/Header";
import Sidebar from "../Sidebar/Sidebar";
import ChatSystem from "./ChatSystem";

const ChatComp = () => {
  const [Loading, setLoading] = useState(false);
  const [Messages, setMessages] = useState([]);
  const [Users, setUsers] = useState([]);
  const [AllUsers, setAllUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [Reload, setReload] = useState(false);

  const fetchUsers = () => {
    db.collection("users")
      .get()
      .then((snapshot) => {
        console.log("Snapshot", snapshot);
        let arr = [];
        snapshot.docs.forEach((doc) => {
          console.log("DOC", doc.data());
          arr.push(doc.data());
        });
        setUsers(arr);
        setAllUsers(arr);
      });
  };

  //
  useEffect(() => {
    fetchUsers();
  }, [Reload]);

  console.log(Messages);
  console.log("USERS", Users);

  // component return
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div style={{ padding: "0" }} className="col-md-2">
            <Sidebar />
          </div>
          {Loading ? (
            <div
              style={{
                height: "90vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="col-md-10"
            >
              <Loader />
            </div>
          ) : (
            <div className="col-md-10">
              <Header search={search} setSearch={setSearch} />
              <div className="col-md-12 mx-auto chatsystem-wrapper">
                <h3>Replies</h3>
                <ChatSystem
                  AllUsers={AllUsers}
                  search={search}
                  Users={Users}
                  setUsers={setUsers}
                  Reload={Reload}
                  setReload={setReload}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ChatComp;
