import React from "react";
import "./Tabs.css";

// components
import Attributes from "../Items/Attributes";
import ProductDetailsForCategory from "../Items/ProductDetailsForCategory";
import Discount from "../Items/Discount";

const Tabs = ({ CurrentPage, setCurrentPage, Pages }) => {
  const currentTab = (name) => {
    if (name == CurrentPage) {
      return "tab-active";
    } else {
      return "";
    }
  };

  return (
    <>
      <div
        style={{ display: "flex", justifyContent: "space-around" }}
        className="multiple-tabs"
      >
        {Pages.map((page) => {
          const { name, index } = page;
          return (
            <div key={index}>
              <h3
                style={{ fontSize: "20px" }}
                className={currentTab(name)}
                onClick={() => {
                  setCurrentPage(name);
                }}
              >
                {name}
              </h3>
            </div>
          );
        })}
      </div>
      {CurrentPage === "Product Details" && (
        <ProductDetailsForCategory
          CurrentPage={CurrentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
      {CurrentPage === "Attributes" && (
        <Attributes CurrentPage={CurrentPage} setCurrentPage={setCurrentPage} />
      )}
      {/* {CurrentPage === "Discount" && (
        <Discount CurrentPage={CurrentPage} setCurrentPage={setCurrentPage} />
      )} */}
    </>
  );
};

export default Tabs;
