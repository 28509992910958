import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { TableContainer, Button } from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { IconButton, Switch } from "@material-ui/core";
import { API } from "../../Backend";
import EditIcon from "@material-ui/icons/Edit";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.common.black,
    padding: "16px !important",
    fontSize: "15px",
  },
  body: {
    fontSize: "14px",
  },
  root: {
    padding: "4px 16px",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function CustomizedTables(props) {
  const classes = useStyles();

  // deleteHandler
  const deleteHandler = async (restroId) => {
    let confirm = window.confirm("Do you really want to delete this?");

    if (confirm) {
      try {
        let response = await fetch(
          `${API}/api/admin/restraurant/${restroId}/delete`,
          {
            method: "DELETE",
          }
        );
        let result = await response.json();
        props.setRefresh(!props.Refresh);

        console.log("TOGGLE RES", result);
      } catch (err) {
        console.log("ERR", err);
      }
    }
  };

  const toggleHandler = async (restroId) => {
    try {
      let response = await fetch(
        `${API}/api/admin/restraurant/${restroId}/toggle`,
        {
          method: "PUT",
        }
      );
      let result = await response.json();
      props.setRefresh(!props.Refresh);

      console.log("TOGGLE RES", result);
    } catch (err) {
      console.log("ERR", err);
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              {props.columns.map((column, ind) => {
                return (
                  <StyledTableCell key={ind} align="left">
                    {column}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows &&
              props.rows
                .reverse()
                .filter((coupon) => {
                  if (props.search && props.search.toLowerCase() == "") {
                    return coupon;
                  } else if (
                    coupon.restroName
                      .toLowerCase()
                      .includes(props.search.toLowerCase())
                  ) {
                    return coupon;
                  }
                })
                .map((row) => (
                  <TableRow key={row._id}>
                    <TableCell component="th" scope="row">
                      {row.restroName}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {""}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {""}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {""}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {""}
                    </TableCell>
                    {/* <TableCell component="th" scope="row">
                      {row.slogan > 15
                        ? `${row.slogan.substring(0, 5)}...`
                        : row.slogan}
                    </TableCell>
                    <TableCell align="left">
                      {row.minOrder ? `$ ${row.minOrder}` : "No Minimum"}
                    </TableCell>
                    <TableCell align="left">
                      {row.prepTime ? ` ${row.prepTime} min` : "No Prep Time"}
                    </TableCell>
                    <TableCell align="left">{row.description}</TableCell> */}

                    <TableCell>
                      <Switch
                        checked={row.active}
                        color="primary"
                        onChange={() => toggleHandler(row._id)}
                        inputProps={{
                          "aria-label": "checkbox with default color",
                        }}
                      />

                      <IconButton onClick={() => props.Edit(row)}>
                        <EditIcon />
                      </IconButton>

                      <IconButton onClick={() => deleteHandler(row._id)}>
                        <DeleteForeverIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      {props.rows.length == 0 && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100px",
            fontSize: 18,
          }}
        >
          <p>Currently No Restraurant </p>
        </div>
      )}
    </>
  );
}
