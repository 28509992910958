import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { isAuthenticated } from "../../helpers/AuthHelper";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    width: "400px",
    height: "auto",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    width: "100%",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs({ setOpen, Open }) {
  const [Values, setValues] = useState({
    name: isAuthenticated().data.user.name,
    email: isAuthenticated().data.user.email,
  });

  const { name, role, email } = Values;

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={Open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <div>
            <h3>Profile</h3>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <TextField
              style={{ width: "100%%" }}
              id="standard-basic"
              label="Name"
              defaultValue={name}
              disabled={true}
            />
            <br />
            {/* <TextField
              style={{ width: "100%" }}
              id="standard-basic"
              label="Role"
              defaultValue={role}
              disabled={true}
            />
            <br /> */}

            <TextField
              style={{ width: "100%" }}
              id="standard-basic"
              label="Email"
              defaultValue={email}
              disabled={true}
            />
          </div>
        </DialogContent>
        <DialogActions>
          {/* <Button autoFocus onClick={handleClose} color="primary">
            Save changes
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
