import React, { useState } from "react";
import "./SetPassword.css";

import { TextField, Button } from "@material-ui/core";

import Loader from "../../Loader/Loader";
import { Redirect, useParams } from "react-router";

const SetPassword = () => {
  // params
  const { adminId } = useParams();

  // useState hooks
  const [Values, setValues] = useState({
    password: "",
    confirmPassword: "",
    didRedirect: false,
    success: false,
  });
  const [Loading, setLoading] = useState(false);

  // destructuring
  const { password, confirmPassword, success, didRedirect } = Values;

  // chnageHandler
  const changeHandler = (name) => (e) => {
    let value = e.target.value;
    setValues({ ...Values, [name]: value });
  };

  // submitHandler
  const submitHandler = (e) => {
    e.preventDefault();

    if (!password || !confirmPassword) {
      alert("Please include both fields first!");
      return "";
    }

    setLoading(true);
  };

  console.log("VALUES", Values);

  // redirectHandler
  const redirectHandler = () => {
    if (success) {
      if (didRedirect) {
        alert("Password Successfully Saved!");
        return <Redirect to="/" />;
      }
    }
  };

  // conditional rendering
  if (Loading) {
    return (
      <div className="form-wrapper">
        <Loader />
      </div>
    );
  }

  return (
    <>
      {redirectHandler()}
      <div className="form-wrapper">
        <form onSubmit={submitHandler}>
          <h2>Set Your Password</h2>
          <TextField
            type="password"
            style={{ width: "100%" }}
            id="standard-basic"
            label="Password"
            defaultValue={password}
            onChange={changeHandler("password")}
          />
          <br />
          <br />

          <TextField
            type="password"
            style={{ width: "100%" }}
            id="standard-basic"
            label="Confirm Password"
            defaultValue={confirmPassword}
            onChange={changeHandler("confirmPassword")}
          />
          <br />
          <br />

          <Button
            type="submit"
            style={{ width: "80%" }}
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </form>
      </div>
    </>
  );
};

export default SetPassword;
