import React, { useState } from "react";

import "./Login.css";
import sidebar from "../../Images/sidebar.png";
import Logo from "../../Images/Logo.png";

import { Redirect } from "react-router-dom";

import { login, setIdInLocalStorage } from "../../helpers/AuthHelper";
import Loader from "../../Loader/Loader";
import toast, { Toaster } from "react-hot-toast";

//* Material-ui
import {
  TextField,
  InputProps,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import LockIcon from "@material-ui/icons/Lock";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const Login = () => {
  // useState hooks
  const [Values, setValues] = useState({
    email: "",
    password: "",
    error: false,
    success: false,
    didRedirect: false,
  });

  const [Loading, setLoading] = useState(false);
  const [type, setType] = useState("password");
  // destructuring
  const { email, password, success, error, didRedirect } = Values;

  //* Login handler
  const loginHandler = (event) => {
    event.preventDefault();

    setValues({ ...Values, error: false, success: false, didRedirect: false });

    //  Loading
    setLoading(true);

    login(Values)
      .then((data) => {
        console.log("LOGIN DATA", data);

        if (data.error) {
          alert(data.message);
          setLoading(false);

          return "";
        }

        setIdInLocalStorage(data.data, () => {
          setValues({
            email: "",
            password: "",
            error: false,
            success: true,
            didRedirect: true,
          });

          setLoading(false);
        });
      })
      .catch((err) => {
        setValues({
          email: "",
          password: "",
          error: true,
          success: false,
          didRedirect: false,
        });
        setLoading(false);
        toast.error("Something went wrong!");
      });
  };

  // changeHandler
  const changeHandler = (name) => (e) => {
    let value = e.target.value;
    setValues({ ...Values, [name]: value });
  };

  // Redirect Handler
  const redirectHandler = () => {
    if (didRedirect) {
      if (success) {
        const { _id } = JSON.parse(localStorage.getItem("Id"));
        return <Redirect to={`/admin/${_id}/check/otp`} />;
      }
    }
  };

  console.log("VALUES", Values);
  console.log("type", type);

  // login component return
  return (
    <div className="my-container">
      <Toaster position="top-center" />
      {redirectHandler()}
      <div className="left">
        <>
          {Loading ? (
            <Loader />
          ) : (
            <form className="login-form" onSubmit={loginHandler}>
              <img className="logo" src={Logo} alt="logo" />

              <div style={{ display: "flex", alignItems: "center" }}>
                <label
                  style={{
                    color: "#2BBFC5",
                    marginTop: "20px",
                    marginRight: "10px",
                  }}
                >
                  <EmailIcon />
                </label>
                <TextField
                  id="standard-basic"
                  style={{ width: "100%" }}
                  label="Enter Email"
                  defaultValue={email}
                  onChange={changeHandler("email")}
                />
              </div>
              <br />

              <div style={{ display: "flex", alignItems: "center" }}>
                <label
                  style={{
                    color: "#2BBFC5",
                    marginTop: "15px",
                    marginRight: "10px",
                  }}
                >
                  <LockIcon />
                </label>

                <TextField
                  type={type == "text" ? "text" : "password"}
                  label="Password"
                  style={{ width: "100%" }}
                  defaultValue={password}
                  onChange={changeHandler("password")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setType(type == "text" ? "password" : "text")
                          }
                        >
                          {type == "text" ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <br />
              <br />
              <br />

              <button type="submit" className="login-btn">
                Login
              </button>
            </form>
          )}
        </>
      </div>
      <div className="right">
        <img src={sidebar} alt="sidebar-img" />
      </div>
    </div>
  );
};

export default Login;
