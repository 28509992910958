import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Modal from "react-modal";

// components
import Sidebar from "../Sidebar/Sidebar";
import Loader from "../../Loader/Loader";
import Header from "../header/Header";

// helpers
import { getTransactions } from "../../helpers/TransactionHelper";

// Table
import Table from "../../Tables/Transactions/TransactionsTable.jsx";

const Transactions = () => {
  // useState hooks
  const [Loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [AllTransactions, setAllTransactions] = useState(false);

  useEffect(() => {
    getTransactions()
      .then((data) => {
        console.log(data.data);
        setAllTransactions(data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  console.log("AllTransactions", AllTransactions);

  //   component return
  return (
    <div className="container-fluid">
      <div className="row">
        <div style={{ padding: "0" }} className="col-md-2">
          <Sidebar />
        </div>
        {Loading ? (
          <div
            className="col-md-10"
            style={{
              height: "90vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader />
          </div>
        ) : (
          <div
            style={{ backgroundColor: "rgb(246, 248, 251)" }}
            className="col-md-10"
          >
            {/* <Toaster /> */}
            <Header search={search} setSearch={setSearch} />
            <h3>Transaction Component</h3>
            <Table
              columns={[
                "Stripe Id",
                "Currency",
                "Amount",
                "Status",
                "Payment Method",
                "Description",
              ]}
              rows={AllTransactions}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Transactions;
