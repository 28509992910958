import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { IconButton } from "@material-ui/core";

import { Link, useParams } from "react-router-dom";
import { Button } from "@material-ui/core";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.common.black,
    padding: "16px !important",
    fontSize: "15px",
  },
  body: {
    fontSize: "14px",
  },
  root: {
    padding: "4px 16px",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function CustomizedTables(props) {
  const classes = useStyles();

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              {props.columns.map((column, index) => {
                return (
                  <StyledTableCell key={index} align="left">
                    {column}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows &&
              props.rows
                .reverse()
                .filter((data) => {
                  if (props.search && props.search.toLowerCase() == "") {
                    return data;
                  } else if (
                    data._id.includes(props.search.toLowerCase()) ||
                    data.title
                      .toLowerCase()
                      .includes(props.search.toLowerCase())
                  ) {
                    return data;
                  }
                })
                .map((row) => (
                  <TableRow key={row._id}>
                    <TableCell align="left" component="th" scope="row">
                      {row._id}
                    </TableCell>
                    <TableCell align="left" component="th" scope="row">
                      {row.title.length > 15
                        ? `${row.title.substring(0, 15)}...`
                        : row.title}
                    </TableCell>
                    <TableCell align="left">
                      <span className="badge bg-primary">
                        {row.status ? "Open" : "Replied"}
                      </span>
                    </TableCell>
                    <TableCell align="left">
                      {row.query.length > 15
                        ? `${row.query.substring(0, 15)}...`
                        : row.query}
                    </TableCell>
                    <TableCell align="left">
                      <Link
                        style={{ color: "white", textDecoration: "none" }}
                        to={`/admin/dashboard/help/ticket/${row._id}/reply`}
                      >
                        <IconButton variant="contained" color="primary">
                          <VisibilityIcon />
                        </IconButton>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      {props.rows.length == 0 && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100px",
            fontSize: 18,
          }}
        >
          <h3>Currently No Tickets</h3>
        </div>
      )}
    </>
  );
}
