import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.white,
    color: theme.palette.common.black,
    padding: "16px !important",
    fontSize: "15px",
  },
  body: {
    fontSize: "14px",
  },
  root: {
    padding: "4px 16px",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function CustomizedTables(props) {
  const classes = useStyles();

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              {props.columns.map((column, index) => {
                return (
                  <StyledTableCell key={index} align="left">
                    {column}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows &&
              props.rows
                .filter((data) => {
                  if (props.search && props.search.toLowerCase() == "") {
                    return data;
                  } else if (data._id.includes(props.search.toLowerCase())) {
                    return data;
                  }
                })
                .reverse()
                .map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="left">
                      {row.extras && row.extras.length > 0
                        ? row.extras.map((extra) => `${extra.extraName} , `)
                        : "-"}
                    </TableCell>
                    <TableCell align="left">{""}</TableCell>
                    <TableCell align="left">{""}</TableCell>
                    <TableCell align="left">
                      {row.sizes && row.sizes.length > 0
                        ? row.sizes[0].size
                        : "-"}
                    </TableCell>
                    <TableCell align="left">{1}</TableCell>
                    <TableCell align="left">AED {row.amount}</TableCell>
                  </TableRow>
                ))}

            <TableRow>
              <TableCell component="th" scope="row">
                <h5 style={{ fontWeight: "500" }}>
                  {"Coupon"}
                  {props.coupon &&
                    props.coupon != null &&
                    props.coupon.discountName &&
                    `-${props.coupon.discountName}`}
                </h5>
              </TableCell>

              <TableCell component="th" scope="row">
                {""}
              </TableCell>
              <TableCell align="left">{""}</TableCell>
              <TableCell align="left">{""}</TableCell>
              <TableCell align="left">{""}</TableCell>
              <TableCell align="left">{""}</TableCell>
              <TableCell align="left">
                <h5 style={{ fontWeight: "700" }}>
                  {props.coupon &&
                  props.coupon != null &&
                  props.coupon.discountType == "PERCENTAGE"
                    ? ` ${props.coupon.discountTypeValue}% Off Applied`
                    : props.coupon && props.coupon != null
                    ? `AED ${
                        props.coupon && props.coupon.discountTypeValue
                      } Off Applied`
                    : "Not Applied"}
                </h5>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <h5 style={{ fontWeight: "500" }}>{"Total"}</h5>
              </TableCell>

              <TableCell component="th" scope="row">
                {""}
              </TableCell>
              <TableCell align="left">{""}</TableCell>
              <TableCell align="left">{""}</TableCell>
              <TableCell align="left">{""}</TableCell>
              <TableCell align="left">{""}</TableCell>
              <TableCell align="left">
                <h5 style={{ fontWeight: "700" }}> AED {props.totalAmount}</h5>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {props.rows.length == 0 && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100px",
            fontSize: 18,
          }}
        >
          <p>Currently Empty</p>
        </div>
      )}
    </>
  );
}
