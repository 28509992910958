import { API } from "../Backend";

// getCategories
export const getCategories = () => {
  return fetch(`${API}/api/admin/get/categories`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

// createCategory
export const createCategory = (data) => {
  return fetch(`${API}/api/admin/create/category`, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: data,
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

// updateCategory
export const updateCategory = (categoryId, data) => {
  return fetch(`${API}/api/admin/category/${categoryId}/update`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
    },
    body: data,
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

// deleteCategory
export const deleteCategory = (categoryId) => {
  return fetch(`${API}/api/admin/category/${categoryId}/delete`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

// getAllSubCategories
export const getAllSubCategories = () => {
  return fetch(`${API}/api/admin/get/sub/categories`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

// getSubCategories
export const getSubCategories = (_id) => {
  return fetch(`${API}/api/user/category/${_id}/sub/categories`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

// createSubCategory
export const createSubCategory = (data) => {
  return fetch(`${API}/api/admin/create/sub/category`, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: data,
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

// updateSubCategory
export const updateSubCategory = (_id, data) => {
  console.log("ID", _id);
  console.log("DATA", data);
  return fetch(`${API}/api/admin/sub/category/${_id}/update`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
    },
    body: data,
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

// deleteSubCategory
export const deleteSubCategory = (_id) => {
  return fetch(`${API}/api/admin/sub/category/${_id}/delete`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

// restaurantCategories
export const restaurantCategories = (id) => {
  return fetch(`${API}/api/admin/restaurant/${id}/categories`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};
