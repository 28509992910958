import { API } from "../Backend";

// getRestraurants
export const getRestraurants = () => {
  return fetch(`${API}/api/admin/dash/get/restraurants`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

// addRestraurant
export const addRestraurant = (data) => {
  return fetch(`${API}/api/admin/add/restraurant`, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: data,
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

// addRestroSchedule
export const addRestroSchedule = (restroId, data) => {
  console.log("DATA", data);

  return fetch(`${API}/api/admin/restraurant/${restroId}/add/schedule`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

export const addTiming = (restroId, data) => {
  return fetch(`${API}/api/admin/restraurant/${restroId}/add/order/details`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};
