import React, { useState } from "react";

// components
import Sidebar from "../Sidebar/Sidebar";
import Header from "../header/Header";
import Loader from "../../Loader/Loader";
import ItemTabs from "../Tabs/ItemTabsForEdit";

import toast, { Toaster } from "react-hot-toast";
import { useHistory } from "react-router-dom";

//material-ui
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const dummyData = [
  { name: "Product Details" },
  { name: "Attributes" },
  // { name: "Discount" },
];

const ItemForEdit = () => {
  const [Loading, setLoading] = useState(false);
  const [CurrentPage, setCurrentPage] = useState("Product Details");

  const history = useHistory();

  // component return
  return (
    <div className="container-fluid">
      <div className="row">
        <div style={{ padding: "0" }} className="col-md-2">
          <Sidebar />
        </div>
        {Loading ? (
          <div
            className="col-md-10"
            style={{
              height: "90vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader />
          </div>
        ) : (
          <div className="col-md-10">
            <Header />
            <Toaster />

            {/* TOP BAR */}
            <div className="top-bar">
              <h3
                style={{ cursor: "pointer" }}
                onClick={() => history.goBack()}
                className="back"
              >
                <ArrowBackIosIcon />
                Back
              </h3>
            </div>
            {/* TOP BAR END */}
            <br />
            <ItemTabs
              Pages={dummyData}
              CurrentPage={CurrentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ItemForEdit;
