import React, { useState, useEffect } from "react";
import Modal from "react-modal";

// css
import "./Categories.css";

// helper functions
import {
  getCategories,
  deleteCategory,
  updateCategory,
  createCategory,
} from "../../helpers/categoryHelper";
import { isAuthenticated } from "../../helpers/AuthHelper";
import { getRestraurants } from "../../helpers/restraurantHelper";
import { restaurantCategories } from "../../helpers/categoryHelper";

// components
import Sidebar from "../Sidebar/Sidebar";
import Header from "../header/Header";
import Loader from "../../Loader/Loader";
import {
  TextField,
  Button,
  Select,
  InputLabel,
  MenuItem,
} from "@material-ui/core";

import AddModal from "../Modal/Modal";

// notifications
import { toast, Toaster } from "react-hot-toast";

import CategoryList from "../CategoryList/CategoryList";

// styles for modal
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    width: "30%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

// element for modal
Modal.setAppElement("#root");

const Offers = () => {
  // AdminId or UserId
  const userId = isAuthenticated().data.user._id;

  // useState hooks
  const [SelectedRestro, setSelectedRestro] = useState("All");
  const [Categories, setCategories] = useState([]);
  const [search, setSearch] = useState("");
  const [Loading, setLoading] = useState(true);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [Restros, setRestros] = useState(false);
  const [Reload, setReload] = useState(false);
  const [Values, setValues] = useState({
    categoryName: "",
    status: true,
    arabicCategoryName: "",
    photo: "",
    restro: "",
    formData: "",
  });

  // for edit
  const [Edit, setEdit] = useState("");
  const [EditModalOpen, setEditModalOpen] = useState(false);
  const [EditValues, setEditValues] = useState({
    categoryName: "",
    status: "",
    photo: "",
    arabicCategoryName: "",
    restro: "",
    formData: "",
  });

  // destructuring
  const { categoryName, status, formData, photo, arabicCategoryName, restro } =
    Values;

  console.log(Values);
  console.log(photo);

  // getAllRestaurants
  const getAllRestaurants = () => {
    getRestraurants()
      .then((data) => {
        console.log("RESTAURANTS", data);
        setRestros(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // getAllCategories
  const getAllCategories = () => {
    getCategories()
      .then((data) => {
        console.log("CATEGORIES", data);
        setCategories(data);
        setValues({ ...Values, formData: new FormData() });
        setLoading(false);
      })
      .catch((err) => {
        console.log("ERROR", err);
        setLoading(false);
      });
  };

  // useEffect
  useEffect(() => {
    getAllRestaurants();
    getAllCategories();
  }, [Reload]);

  // getRestaurantCategories
  const getRestaurantCategories = (id) => {
    restaurantCategories(id)
      .then((data) => {
        console.log("RESTRO CATEGORIES", data);
        setCategories(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (SelectedRestro && SelectedRestro != "All") {
      getRestaurantCategories(SelectedRestro);
    }
    if (SelectedRestro == "All") {
      getAllCategories();
    }
  }, [SelectedRestro]);

  // changeHandler
  const changeHandler = (name) => (e) => {
    let value = name === "photo" ? e.target.files[0] : e.target.value;
    formData.set(name, value);
    setValues({ ...Values, [name]: value });
  };

  // addCategoryHandler
  const addCategoryHandler = (e) => {
    e.preventDefault();
    formData.append("status", status);
    setLoading(true);

    createCategory(formData)
      .then((data) => {
        if (data.error) {
          setLoading(false);
          toast.error(data.message);
          return "";
        }
        setValues({
          categoryName: "",
          status: true,
          arabicCategoryName: "",
          restro: "",
        });
        setLoading(false);
        setReload(!Reload);
        setIsOpen(false);
        toast.success(data.message);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  // changeEditHandler
  const changeEditHandler = (name) => (e) => {
    const { formData } = EditValues;

    let value = name === "photo" ? e.target.files[0] : e.target.value;
    formData.set(name, value);
    setEditValues({ ...EditValues, [name]: value });
  };

  // updateCategoryHandler
  const updateCategoryHandler = (e) => {
    e.preventDefault();

    setLoading(true);

    const { formData } = EditValues;

    updateCategory(Edit._id, formData)
      .then((data) => {
        if (data.error) {
          setLoading(false);
          toast.error(data.message);
          return "";
        }
        console.log(data);
        setEditModalOpen(false);
        setLoading(false);
        toast.success(data.message);
        setReload(!Reload);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error("Something went wrong!");
      });
  };

  // deleteCategoryHandler
  const deleteCategoryHandler = (_id) => {
    deleteCategory(_id)
      .then((data) => {
        console.log(data);
        toast.success("Deleted Successfully!");
        setReload(!Reload);
      })
      .catch((err) => console.error(err));
  };

  // changeRestroHandler
  const changeRestroHandler = (e) => {
    console.log("SELECTED RESTRO", e.target.value);
    setSelectedRestro(e.target.value);
  };

  // modal close functions
  const closeModal = () => {
    setIsOpen(false);
    setValues({
      ...Values,
      categoryName: "",
      arabicCategoryName: "",
      status: true,
      photo: "",
      restro: "",
    });
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
  };

  //* offers return
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div style={{ padding: "0" }} className="col-md-2">
            <Sidebar />
          </div>
          {/* Conditional rendering */}
          {Loading ? (
            <div
              className="col-md-10"
              style={{
                height: "90vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader />
            </div>
          ) : (
            <div
              style={{ padding: "2px", backgroundColor: "rgb(246, 248, 251)" }}
              className="col-md-10"
            >
              <Toaster />
              <Header search={search} setSearch={setSearch} />
              <div className="header-wrapper">
                <h3>Categories</h3>
                <div
                  style={{
                    width: "30%",
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <button onClick={() => setIsOpen(true)} className="user-btn">
                    Add Category
                  </button>
                </div>
              </div>

              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={SelectedRestro}
                onChange={changeRestroHandler}
              >
                <MenuItem value="All">All Restaurants</MenuItem>
                {Restros &&
                  Restros.map((restro) => {
                    const { restroName, _id } = restro;
                    return (
                      <MenuItem key={_id} value={_id}>
                        {restroName ? `${restroName}` : restroName}
                      </MenuItem>
                    );
                  })}
              </Select>

              {/* MODAL for Adding*/}
              <AddModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen}>
                <form onSubmit={addCategoryHandler}>
                  <h3 className="text-center">Add Category</h3>
                  <TextField
                    style={{ width: "100%" }}
                    id="standard-basic"
                    label="Category Name"
                    defaultValue={categoryName}
                    onChange={changeHandler("categoryName")}
                  />

                  <br />
                  <br />

                  <TextField
                    style={{ width: "100%" }}
                    id="standard-basic"
                    label="Category Name (in arabic)"
                    defaultValue={arabicCategoryName}
                    onChange={changeHandler("arabicCategoryName")}
                  />

                  <br />
                  <br />
                  <InputLabel id="demo-simple-select-label">
                    Select Restaurant
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={restro}
                    onChange={changeHandler("restro")}
                  >
                    {Restros &&
                      Restros.map((restro) => {
                        const { restroName, _id } = restro;
                        return (
                          <MenuItem key={_id} value={_id}>
                            {restroName}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <br />
                  <br />

                  <Button variant="contained" component="label">
                    Upload Image
                    <input
                      onChange={changeHandler("photo")}
                      type="file"
                      hidden
                    />
                  </Button>
                  {photo ? (
                    <>
                      <br />
                      <br />
                      <span className="badge bg-primary"> {photo.name}</span>
                    </>
                  ) : (
                    ""
                  )}
                  <br />
                  <br />

                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    defaultValue={status}
                    onChange={changeHandler("status")}
                  >
                    <MenuItem value="true">True</MenuItem>
                    <MenuItem value="false">False</MenuItem>
                  </Select>
                  <br />
                  <br />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button type="submit" variant="contained" color="primary">
                      Add New Category
                    </Button>

                    <Button
                      onClick={closeModal}
                      variant="contained"
                      color="secondary"
                    >
                      Cancel
                    </Button>
                  </div>
                </form>
              </AddModal>
              {/*Adding MODAL END */}

              {/* Modal for Edit */}
              {Edit && (
                <Modal
                  isOpen={EditModalOpen}
                  onRequestClose={closeEditModal}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  {/* Form Start */}
                  <form onSubmit={updateCategoryHandler}>
                    <h3 className="text-center">Update Category</h3>
                    <TextField
                      style={{ width: "100%" }}
                      id="standard-basic"
                      label="Category Name"
                      defaultValue={Edit.categoryName}
                      onChange={changeEditHandler("categoryName")}
                    />
                    <br />
                    <br />

                    <TextField
                      style={{ width: "100%" }}
                      id="standard-basic"
                      label="Category Name"
                      defaultValue={Edit.arabicCategoryName}
                      onChange={changeEditHandler("arabicCategoryName")}
                    />
                    <br />
                    <br />

                    {/* <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      defaultValue={Edit.restro}
                      onChange={changeEditHandler("restro")}
                    >
                      {Restros &&
                        Restros.map((restro) => {
                          const { restroName, _id } = restro;
                          return (
                            <MenuItem key={_id} value={_id}>
                              {restroName}
                            </MenuItem>
                          );
                        })}
                    </Select>

                    <br />
                    <br /> */}

                    <Button variant="contained" component="label">
                      Upload Image
                      <input
                        onChange={changeEditHandler("photo")}
                        type="file"
                        hidden
                      />
                    </Button>
                    <br />
                    <br />

                    <InputLabel id="demo-simple-select-label">
                      Status
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      defaultValue={Edit.status}
                      onChange={changeEditHandler("status")}
                    >
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </Select>
                    <br />
                    <br />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button type="submit" variant="contained" color="primary">
                        Update Category
                      </Button>

                      <Button
                        onClick={closeEditModal}
                        variant="contained"
                        color="secondary"
                      >
                        Cancel
                      </Button>
                    </div>
                  </form>

                  {/* Form End */}
                </Modal>
              )}
              {/* Edit Modal End */}

              <CategoryList
                Delete={(_id) => {
                  deleteCategoryHandler(_id);
                }}
                Edit={(data) => {
                  console.log("DATA", data);
                  setEdit(data);
                  setEditValues({
                    categoryName: data.categoryName,
                    status: data.status,
                    arabicCategoryName: data.arabicCategoryName,
                    formData: new FormData(),
                    restro: data.restro,
                  });
                  setEditModalOpen(true);
                }}
                search={search}
                Categories={Categories}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Offers;
