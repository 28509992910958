import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { TableContainer, Button, IconButton } from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.common.black,
    padding: "16px !important",
    fontSize: "15px",
  },
  body: {
    fontSize: "14px",
  },
  root: {
    padding: "4px 16px",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function CustomizedTables(props) {
  const classes = useStyles();

  // deleteHandler
  const deleteHandler = (_id) => {
    let confirm = window.confirm("Do you really want to delete this?");

    if (confirm) {
      props.Delete(_id);
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              {props.columns &&
                props.columns.map((column, ind) => {
                  return (
                    <StyledTableCell key={ind} align="left">
                      {column}
                    </StyledTableCell>
                  );
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows &&
              props.rows
                .filter((data) => {
                  if (props.search && props.search.toLowerCase() === "") {
                    return data;
                  } else if (
                    data.discountName.includes(props.search.toLowerCase())
                  ) {
                    return data;
                  }
                })
                .reverse()
                .map((row) => (
                  <TableRow key={row._id}>
                    <TableCell component="th" scope="row">
                      {row.discountName}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      {row.discountType}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      {row.discountType == "PERCENTAGE"
                        ? `${row.discountTypeValue}%`
                        : `AED ${row.discountTypeValue}`}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      {row.startDate && row.startDate.slice(0, 10)}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      {row.startTime && row.startTime.slice(11, -9)}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      {row.endDate && row.endDate.slice(0, 10)}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      {row.endTime && row.endTime.slice(11, -9)}
                    </TableCell>

                    <TableCell align="left">
                      {row.active ? (
                        <span className="badge bg-primary">Active</span>
                      ) : (
                        <span className="badge bg-danger">Ended</span>
                      )}
                    </TableCell>

                    <TableCell align="left">
                      <IconButton
                        onClick={() => props.Edit(row)}
                        variant="contained"
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => deleteHandler(row._id)}
                        variant="contained"
                        color="secondary"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      {props.rows.length == 0 && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100px",
            fontSize: 18,
          }}
        >
          <p>Currently No Offers </p>
        </div>
      )}
    </>
  );
}
