import React, { useState } from "react";

// components
import Sidebar from "../Sidebar/Sidebar";
import Header from "../header/Header";
import Tabs from "../Tabs/OrderTabs";

const dummyData = [
  { name: "Orders" },
  { name: "Pending Orders" },
  // { name: "History" },
];

const Order = () => {
  const [CurrentPage, setCurrentPage] = useState("Orders");
  const [search, setSearch] = useState("");

  return (
    <div className="container-fluid">
      <div className="row">
        <div style={{ padding: "0" }} className="col-md-2">
          <Sidebar />
        </div>
        <div
          style={{
            overflowY: "auto",
            backgroundColor: "rgb(246, 248, 251)",
            padding: "2px",
          }}
          className="col-md-10"
        >
          <Header search={search} setSearch={setSearch} />
          <Tabs
            search={search}
            setSearch={setSearch}
            CurrentPage={CurrentPage}
            setCurrentPage={setCurrentPage}
            Pages={dummyData}
          />
        </div>
      </div>
    </div>
  );
};

export default Order;
