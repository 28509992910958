import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import ScrollableFeed from "react-scrollable-feed";

import { Select, MenuItem } from "@material-ui/core";
import { updateOrderStatus } from "../../helpers/orderHelper";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.common.black,
    padding: "16px !important",
    fontSize: "15px",
  },
  body: {
    fontSize: "14px",
  },
  root: {
    padding: "4px 16px",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function CustomizedTables(props) {
  const classes = useStyles();

  // changeHandler
  const changeHandler = (orderId) => (e) => {
    let status = e.target.value;
    updateOrderStatus(orderId, status)
      .then((data) => {
        console.log(data);
        props.setReload(!props.Reload);
        props.toast.success("Status Updated!");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              {props.columns &&
                props.columns.map((column, index) => {
                  return (
                    <StyledTableCell key={index} align="center">
                      {column}
                    </StyledTableCell>
                  );
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {console.log("props.row", props.row)}
            {props.rows &&
              props.rows
                .filter((data) => {
                  if (props.search && props.search.toLowerCase() == "") {
                    return data;
                  } else if (data._id.includes(props.search.toLowerCase())) {
                    return data;
                  }
                })
                .reverse()
                .map((row) => (
                  <TableRow key={row._id}>
                    <TableCell component="th" align="center" scope="row">
                      <Link to={`/admin/dashboard/order/${row._id}`}>
                        {row._id}
                      </Link>
                    </TableCell>
                    <TableCell component="th" align="center" scope="row">
                      {row.orderItems.length > 1 ? (
                        <span style={{ fontWeight: "500" }}>
                          {`${row.orderItems[0].name} +${
                            row.orderItems.length - 1
                          }
                          
                          `}
                        </span>
                      ) : (
                        <span style={{ fontWeight: "500" }}>
                          {row.orderItems[0].name}
                        </span>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {`${row.createdAt && row.createdAt.slice(11, -5)} `}
                    </TableCell>
                    <TableCell align="center">
                      {row.delieveryType == "Delivery"
                        ? "Delivery"
                        : "Take Away"}
                    </TableCell>
                    <TableCell align="center">
                      {row.status == "APPROVED" && (
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          defaultValue={row.status}
                          onChange={changeHandler(row._id)}
                        >
                          <MenuItem disabled value="APPROVED">
                            APPROVED
                          </MenuItem>

                          <MenuItem value="PREPARING">Preparing</MenuItem>
                          <MenuItem value="OUTFORDELIVERY">
                            Out For Delivery
                          </MenuItem>
                          <MenuItem value="DELIVERED">Delivered</MenuItem>
                        </Select>
                      )}

                      {row.status == "PREPARING" && (
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          defaultValue={row.status}
                          onChange={changeHandler(row._id)}
                        >
                          <MenuItem disabled value="APPROVED">
                            APPROVED
                          </MenuItem>

                          <MenuItem disabled value="PREPARING">
                            Preparing
                          </MenuItem>
                          <MenuItem value="OUTFORDELIVERY">
                            Out For Delivery
                          </MenuItem>
                          <MenuItem value="DELIVERED">Delivered</MenuItem>
                        </Select>
                      )}

                      {row.status == "OUTFORDELIVERY" && (
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          defaultValue={row.status}
                          onChange={changeHandler(row._id)}
                        >
                          <MenuItem disabled value="APPROVED">
                            Approved
                          </MenuItem>

                          <MenuItem disabled value="PREPARING">
                            Preparing
                          </MenuItem>
                          <MenuItem disabled value="OUTFORDELIVERY">
                            Out For Delivery
                          </MenuItem>
                          <MenuItem value="DELIVERED">Delivered</MenuItem>
                        </Select>
                      )}

                      {row.status === "PENDING" && (
                        <span className="badge bg-secondary">Pending</span>
                      )}

                      {row.status === "REJECTED" && (
                        <span className="badge bg-danger">Rejected</span>
                      )}

                      {row.status === "DELIVERED" && (
                        <span className="badge bg-success">Delivered</span>
                      )}
                    </TableCell>
                    <TableCell align="center">AED {row.totalAmount}</TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      {props.rows.length == 0 && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100px",
            fontSize: 18,
          }}
        >
          <h3>Currently Empty</h3>
        </div>
      )}
    </>
  );
}
