import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import "./User.css";

// components
import Sidebar from "../Sidebar/Sidebar";
import Header from "../header/Header";
import AddModal from "../Modal/Modal";
import Loader from "../../Loader/Loader";

import { useHistory } from "react-router-dom";
import { Button, IconButton } from "@material-ui/core";
import Profile from "../../Images/Profile.jpg";

// helper
import {
  getAdminUsers,
  addNewUser,
  updateUser,
  deleteUser,
} from "../../helpers/adminHelper";

// material ui
import { TextField, Select } from "@material-ui/core";

// material icons
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { isAuthenticated } from "../../helpers/AuthHelper";
import toast, { Toaster } from "react-hot-toast";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    width: "30%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

const Users = () => {
  // useState hooks
  const [modalIsOpen, setIsOpen] = useState(false);
  const [Loading, setLoading] = useState(true);
  const [Reload, setReload] = useState(false);
  const [Users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  // for Edit user
  const [Edit, setEdit] = useState("");
  const [EditModalOpen, setEditModalOpen] = useState(false);
  const [EditValues, setEditValues] = useState({
    id: "",
    name: "",
    email: "",
    role: "",
    photo: "",
    formData: "",
  });

  // for add user
  const [Values, setValues] = useState({
    name: "",
    email: "",
    role: "Staff",
    photo: "",
    formData: "",
  });

  // destructuring
  const { name, email, role, photo, formData } = Values;
  const adminId = isAuthenticated().data.user._id;

  // getAllUsers
  const getAllUsers = () => {
    getAdminUsers()
      .then((data) => {
        console.log("USERS", data);
        setUsers(data);
        setValues({ ...Values, formData: new FormData() });
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // useEffect
  useEffect(() => {
    getAllUsers();
  }, [Reload]);

  //* Modal Functions
  function openModal() {
    setIsOpen(true);
  }

  // changeHandler
  const changeHandler = (name) => (e) => {
    let value = name === "photo" ? e.target.files[0] : e.target.value;
    formData.set(name, value);
    setValues({ ...Values, [name]: value });
  };

  // submitHandler
  const submitHandler = (e) => {
    e.preventDefault();

    if (Values.name.length < 3) {
      alert("Please Enter Name With Length More than 3!");
      return;
    }

    formData.append("role", role);

    setLoading(true);

    addNewUser(formData)
      .then((data) => {
        if (data.error) {
          setLoading(false);
          toast.error(data.message);
          return "";
        }
        console.log("ADD USER RESPONSE", data);
        setLoading(false);
        setValues({
          name: "",
          email: "",
          role: "Staff",
          photo: "",
          formData: "",
        });
        setIsOpen(false);
        setReload(!Reload);
        toast.success(data.message);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  // changeHandler
  const changeEditHandler = (name) => (event) => {
    const { formData } = EditValues;

    let value = name === "photo" ? event.target.files[0] : event.target.value;
    formData.set(name, value);
    setEditValues({ ...EditValues, [name]: value });
  };

  // updateHandler
  const updateHandler = (e) => {
    e.preventDefault();

    setLoading(true);

    console.log("EDIT VALUES", EditValues);

    const { name, email, formData, photo, role } = EditValues;

    updateUser(Edit._id, formData)
      .then((data) => {
        if (data.error) {
          setLoading(false);
          toast.error(data.message);
          return;
        }
        console.log("USER UPDATE RESPONSE", data);
        setReload(!Reload);
        closeEditModal();
        setLoading(false);
        toast.success(data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // deleteHandler
  const deleteHandler = (_id) => {
    let confirm = window.confirm("Do you really want to Delete this?");

    if (confirm) {
      setLoading(true);

      deleteUser(_id)
        .then((data) => {
          console.log(data);
          setLoading(false);
          setReload(!Reload);
          toast.success("User Deleted Successfully!");
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    }
  };

  const history = useHistory();

  const closeAddModal = () => {
    setIsOpen(false);

    setValues({
      name: "",
      email: "",
      role: "Staff",
      photo: "",
      formData: "",
    });
  };

  const closeEditModal = () => {
    setEditModalOpen(false);

    setEditValues({
      name: "",
      email: "",
      role: "Staff",
      photo: "",
      formData: "",
    });
  };

  console.log("FORMDATA", formData);

  let normalUsers =
    Users &&
    Users.filter((user) => {
      if (user.userRole == "0") {
        return "";
      } else {
        return user;
      }
    }).length;

  console.log("normalUsers", normalUsers);
  // users return
  return (
    <div className="container-fluid">
      <div className="row">
        <div style={{ padding: "0" }} className="col-md-2">
          <Sidebar />
        </div>

        {Loading ? (
          <div
            className="col-md-10"
            style={{
              height: "90vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader />
          </div>
        ) : (
          <div style={{ padding: "3px" }} className="col-md-10">
            <Toaster />
            <Header search={search} setSearch={setSearch} />
            <div className="top-bar">
              <h3
                onClick={() => history.goBack()}
                style={{ cursor: "pointer" }}
                className="back"
              >
                <ArrowBackIosIcon />
                Back
              </h3>
              <button onClick={openModal} className="user-btn">
                Add New Role
              </button>
            </div>

            {/* Modal for Adding */}
            <AddModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen}>
              <form onSubmit={submitHandler}>
                <h3 className="text-center">Add User</h3>

                <TextField
                  style={{ width: "100%" }}
                  id="standard-basic"
                  label="User Name"
                  defaultValue={name}
                  onChange={changeHandler("name")}
                />
                <br />
                <br />

                <Button variant="contained" component="label">
                  {photo ? photo.name : "Upload Image"}
                  <input onChange={changeHandler("photo")} type="file" hidden />
                </Button>
                <br />
                <br />

                <TextField
                  style={{ width: "100%" }}
                  id="standard-basic"
                  label="User Email"
                  defaultValue={email}
                  onChange={changeHandler("email")}
                />
                <br />
                <br />

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  defaultValue={role}
                  onChange={changeHandler("role")}
                >
                  <option value="Manager">Manager</option>
                  <option value="Staff">Staff</option>
                </Select>
                <br />
                <br />

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button type="submit" variant="contained" color="primary">
                    Add New User
                  </Button>

                  <Button
                    onClick={closeAddModal}
                    variant="contained"
                    color="secondary"
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </AddModal>
            {/* Modal for Adding End */}

            {/* Modal for Edit */}
            {Edit && (
              <Modal
                isOpen={EditModalOpen}
                onRequestClose={closeEditModal}
                style={customStyles}
              >
                <form onSubmit={updateHandler}>
                  <h3 className="text-center">Add User</h3>

                  <TextField
                    style={{ width: "100%" }}
                    id="standard-basic"
                    label="User Name"
                    defaultValue={Edit.name}
                    onChange={changeEditHandler("name")}
                  />
                  <br />
                  <br />

                  <Button variant="contained" component="label">
                    Upload Photo
                    <input
                      onChange={changeEditHandler("photo")}
                      type="file"
                      hidden
                    />
                  </Button>
                  <br />
                  <br />

                  <TextField
                    style={{ width: "100%" }}
                    id="standard-basic"
                    label="User Email"
                    defaultValue={Edit.email}
                    onChange={changeEditHandler("email")}
                  />
                  <br />
                  <br />

                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    defaultValue={Edit.userRole}
                    onChange={changeEditHandler("userRole")}
                  >
                    <option value="Manager">Manager</option>
                    <option value="Staff">Staff</option>
                  </Select>
                  <br />
                  <br />
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button type="submit" variant="contained" color="primary">
                      Update User
                    </Button>

                    <Button
                      onClick={closeEditModal}
                      variant="contained"
                      color="secondary"
                    >
                      Cancel
                    </Button>
                  </div>
                </form>
              </Modal>
            )}
            {/* Modal for Edit Ending*/}

            {normalUsers == 0 && (
              <h3 className="text-center mt-5">Currently No Users Added</h3>
            )}
            <div className="user-cards-wrapper">
              {Users &&
                Users.filter((user) => user._id != adminId)
                  .filter((userData) => {
                    if (search && search.toLowerCase() == "") {
                      return userData;
                    } else if (
                      userData.name.toLowerCase().includes(search.toLowerCase())
                    ) {
                      return userData;
                    }
                  })
                  .filter((userData) => {
                    if (userData && userData.userRole == "0") {
                      return "";
                    } else {
                      return userData;
                    }
                  })
                  .map((user) => {
                    const { _id, name, userRole, profileUrl, email } = user;
                    return (
                      <div key={_id} className="user-card">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            // justifyContent: "space-evenly",
                            height: "100%",
                          }}
                        >
                          <div
                            style={{
                              width: "30%",
                              height: "100%",
                              marginRight: "15px",
                            }}
                            className="user-img-wrapper"
                          >
                            <img
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                              src={profileUrl ? profileUrl : Profile}
                              alt="user-img"
                            />
                          </div>
                          <div className="user-info-wrapper">
                            <h4>{name}</h4>
                            <h4>{userRole}</h4>
                            <h5>{email}</h5>
                          </div>
                        </div>
                        <br />
                        <div>
                          {isAuthenticated().data.user.name == "Admin" && (
                            <div
                              style={{
                                display: "flex",
                                position: "absolute",
                                bottom: "5px",
                                right: "5px",
                              }}
                              className="buttons"
                            >
                              <IconButton
                                onClick={() => {
                                  setEditModalOpen(true);
                                  setEdit(user);
                                  setEditValues({
                                    id: user._id,
                                    name: user.name,
                                    role: user.userRole,
                                    email: user.email,
                                    formData: new FormData(),
                                  });
                                }}
                                variant="contained"
                                color="primary"
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => deleteHandler(_id)}
                                variant="contained"
                                color="secondary"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                    {
                      /* user-card end */
                    }
                  })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Users;
