import React, { useEffect, useState } from "react";
import "./Restro.css";

// components
import Sidebar from "../Sidebar/Sidebar";
import Loader from "../../Loader/Loader";
import Header from "../header/Header";
import toast from "react-hot-toast";
import Modal from "react-modal";
import { TextField, Button } from "@material-ui/core";

// Table
import Table from "../../Tables/Restraurant/RestroTable";
import { Link, useHistory } from "react-router-dom";

// helpers
import { getRestraurants } from "../../helpers/restraurantHelper";
import { API } from "../../Backend";

// styles for modal
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    width: "30%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Restro = () => {
  const [search, setSearch] = useState("");
  const [Loading, setLoading] = useState(true);
  const [Restros, setRestros] = useState([]);
  const [Refresh, setRefresh] = useState(false);
  const [Edit, setEdit] = useState("");
  const [EditModalOpen, setEditModalOpen] = useState(false);
  const [FormError, setFormError] = useState({
    restroName: false,
    address: false,
    city: false,
    province: false,
    zip: false,
    deliveryRadius: false,
  });
  const [Values, setValues] = useState({
    restroName: "",
    prepTime: "",
    minOrder: "",
    slogan: "",
    description: "",
    address: "",
    city: "",
    province: "",
    zip: "",
    deliveryRadius: "",
  });

  const {
    restroName,
    prepTime,
    minOrder,
    slogan,
    description,
    address,
    city,
    province,
    zip,
    deliveryRadius,
  } = Values;

  const getAllRestraurants = () => {
    getRestraurants()
      .then((data) => {
        console.log("DATA", data);
        setRestros(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        toast.error("Something went wrong!");
      });
  };

  useEffect(() => {
    getAllRestraurants();
  }, [Refresh]);

  const closeEditModal = () => {
    setEditModalOpen(false);
  };

  const updateHandler = () => {
    for (var prop in Values) {
      if (
        Values[prop] === null ||
        Values[prop] === undefined ||
        Values[prop] == ""
      ) {
        delete Values[prop];
      }
    }
    console.log("New Values", Values);
    if (Validate()) {
      setLoading(true);

      fetch(`${API}/api/admin/restraurant/${Edit._id}/update`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(Values),
      })
        .then((res) => {
          console.log("response", res);
          closeEditModal();
          setRefresh(!Refresh);
          setLoading(false);
        })
        .catch((err) => {
          console.log("ERR", err);
          setLoading(false);
          alert(err);
        });
    }
  };

  const changeHandler = (e) => {
    console.log("Value", e.target.value);
    setValues({ ...Values, [e.target.name]: e.target.value });
  };

  let history = useHistory();

  console.log("Values", Values);

  const Validate = () => {
    let value = true;

    let err = {
      restroName: false,
      address: false,
      city: false,
      province: false,
      zip: false,
      deliveryRadius: false,
    };

    if (restroName == "") {
      value = false;
      err.restroName = "Please Enter Restaurant Name First!";
    }

    if (address == "") {
      value = false;
      err.address = "Please Enter Address First!";
    }

    if (city == "") {
      value = false;
      err.city = "Please Enter City First!";
    }

    if (province == "") {
      value = false;
      err.province = "Please Enter Province First!";
    }

    if (zip == "") {
      value = false;
      err.zip = "Please Enter Province First!";
    } else if (zip < 0) {
      value = false;
      err.zip = "Please Enter Valid Province First!";
    }

    if (deliveryRadius == "") {
      value = false;
      err.deliveryRadius = "Please Enter Delivery Radius First!";
    } else if (deliveryRadius < 0) {
      value = false;
      err.deliveryRadius = "Please Enter Valid Delivery First!";
    }

    setFormError({ ...err });
    return value;
  };

  useEffect(() => {
    if (Edit) {
      setValues({ ...Edit });
    }
  }, [Edit]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div style={{ padding: "0" }} className="col-md-2">
            <Sidebar />
          </div>
          {/* Conditional rendering */}
          {Loading ? (
            <div
              className="col-md-10"
              style={{
                height: "90vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader />
            </div>
          ) : (
            <div className="col-md-10">
              <Header search={search} setSearch={setSearch} />
              <div className="header-wrapper">
                <h3>Restaurants</h3>
                <button
                  className="user-btn"
                  onClick={() =>
                    history.push("/admin/dashboard/setting/add/restraurant")
                  }
                >
                  {/* <Link
                    style={{ textDecoration: "none" }}
                    className="text-white"
                    to="/admin/dashboard/setting/add/restraurant"
                  > */}
                  Add Restaurant
                  {/* </Link> */}
                </button>
              </div>

              {Edit && (
                <Modal
                  isOpen={EditModalOpen}
                  onRequestClose={closeEditModal}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  <h3 className="text-center">Update Restaurant Name</h3>
                  <br />
                  <TextField
                    onChange={changeHandler}
                    value={restroName}
                    style={{ width: "100%" }}
                    id="standard-basic"
                    label="Restro Name"
                    name="restroName"
                    error={FormError.restroName}
                    helperText={FormError.restroName}
                  />
                  <br />
                  <br />
                  <TextField
                    onChange={changeHandler}
                    value={address}
                    style={{ width: "100%" }}
                    id="standard-basic"
                    label="Address"
                    name="address"
                    error={FormError.address}
                    helperText={FormError.address}
                  />
                  <br />
                  <br />

                  <TextField
                    onChange={changeHandler}
                    value={city}
                    style={{ width: "100%" }}
                    id="standard-basic"
                    label="City"
                    name="city"
                    error={FormError.city}
                    helperText={FormError.city}
                  />
                  <br />
                  <br />

                  <TextField
                    onChange={changeHandler}
                    value={province}
                    style={{ width: "100%" }}
                    id="standard-basic"
                    label="Province"
                    name="province"
                    error={FormError.province}
                    helperText={FormError.province}
                  />
                  <br />
                  <br />

                  <TextField
                    type="number"
                    onChange={changeHandler}
                    value={zip}
                    style={{ width: "100%" }}
                    id="standard-basic"
                    label="Zip"
                    name="zip"
                    error={FormError.zip}
                    helperText={FormError.zip}
                  />
                  <br />
                  <br />

                  <TextField
                    type="number"
                    onChange={changeHandler}
                    value={deliveryRadius}
                    style={{ width: "100%" }}
                    id="standard-basic"
                    label="Delivery Radius"
                    name="deliveryRadius"
                    error={FormError.deliveryRadius}
                    helperText={FormError.deliveryRadius}
                  />
                  <br />
                  <br />
                  <Button
                    onClick={updateHandler}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Update
                  </Button>
                </Modal>
              )}

              <br />
              <Table
                Edit={(data) => {
                  setEdit(data);
                  setEditModalOpen(true);
                }}
                search={search}
                rows={Restros}
                Refresh={Refresh}
                setRefresh={setRefresh}
                columns={[
                  "Restaurant Name",
                  "",
                  "",
                  "",
                  "",
                  "Actions",
                  // "Slogan",
                  // "Min. Order",
                  // "Prep Time",
                  // "Description",
                  // "Action",
                ]}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Restro;
