import React, { useState, useEffect } from "react";

// Components
import Header from "../header/Header";
import Sidebar from "../Sidebar/Sidebar";
import Loader from "../../Loader/Loader";

// Table
import Table from "../../Tables/Customers/CustomersTable";

// helpers
import {
  getCustomers,
  getCustomersWithOrdersCount,
} from "../../helpers/customerHelper";

const Customers = () => {
  const [search, setSearch] = useState("");
  const [Loading, setLoading] = useState(true);
  const [Users, setUsers] = useState([]);
  const [Reload, setReload] = useState(false);
  const [UsersOrders, setUsersOrders] = useState([]);

  const getAllCustomersOrderCount = () => {
    getCustomersWithOrdersCount()
      .then((data) => {
        console.log("CUSTOMERS with order counts", data.result);
        setUsersOrders(data.result);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getAllCustomers = () => {
    getCustomers()
      .then((data) => {
        console.log("customers", data.customers);
        setUsers(data.customers);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllCustomersOrderCount();
    getAllCustomers();
  }, [Reload]);

  console.log("userOrders", UsersOrders);

  // Component Return
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div style={{ padding: "0" }} className="col-md-2">
            <Sidebar />
          </div>
          {Loading ? (
            <div
              style={{
                height: "90vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="col-md-10"
            >
              <Loader />
            </div>
          ) : (
            <div
              className="col-md-10"
              style={{
                backgroundColor: "#F6F8FB",
                height: "100vh",
              }}
            >
              <Header search={search} setSearch={setSearch} />

              <Table
                UsersOrders={UsersOrders ? UsersOrders : []}
                Reload={Reload}
                setReload={setReload}
                search={search}
                rows={Users}
                columns={[
                  "User Id",
                  "Name",
                  "Email",
                  "Orders",
                  "Contact No.",
                  "Actions",
                ]}
                style={{ boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.3)" }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Customers;
