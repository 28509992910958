import React, { useState } from "react";
import { Redirect, useParams } from "react-router";

import sidebar from "../../Images/sidebar.png";
import Logo from "../../Images/Logo.png";
import "./CheckOtp.css";

import OtpInput from "./OtpInputComponent";
import Loader from "../../Loader/Loader";

import {
  checkOtp,
  authenticate,
  isAuthenticated,
} from "../../helpers/AuthHelper";

const CheckOtp = () => {
  //* parameter extraction
  const { adminId } = useParams();

  //* useState hooks
  const [Otp, setOtp] = useState(new Array(4).fill(""));
  const [Loading, setLoading] = useState(false);
  const [DidRedirect, setDidRedirect] = useState(false);

  //* loginHandler
  const loginHandler = (e) => {
    setLoading(true);

    let otp = "";
    for (let i = 0; i < Otp.length; i++) {
      otp += Otp[i];
    }

    if (!otp || otp.length < 4) {
      alert("Please Insert OTP First!");
      setLoading(false);
      return "";
    }

    checkOtp(adminId, otp)
      .then((data) => {
        console.log("DATA", data);

        if (data.error) {
          alert(data.message);
          setLoading(false);

          return "";
        }

        authenticate(data, () => {
          setOtp(new Array(4).fill(""));
          setDidRedirect(true);
          setLoading(false);
        });
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  console.log("Auth", isAuthenticated());

  //* performRedirect
  const performRedirect = () => {
    if (DidRedirect) {
      if (isAuthenticated()) {
        return <Redirect to="/admin/dashboard/overview" />;
      }
    }
  };

  //* Component Return
  return (
    <div className="my-container">
      {performRedirect()}
      <div className="left">
        {Loading ? (
          <Loader />
        ) : (
          <div style={{ textAlign: "center" }}>
            <img className="logo" src={Logo} alt="logo" />
            <br />
            <OtpInput Otp={Otp} setOtp={setOtp} />

            <button onClick={loginHandler} className="login-btn otp-btn">
              Login
            </button>
          </div>
        )}
      </div>

      <div className="right">
        <img src={sidebar} alt="sidebar" />
      </div>
    </div>
  );
};

export default CheckOtp;
