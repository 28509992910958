import { API } from "../Backend";

// getCustomers
export const getCustomersWithOrdersCount = () => {
  return fetch(`${API}/api/admin/get/all/users/orders`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => response.json())
    .catch((err) => console.error(err));
};

export const getCustomers = () => {
  return fetch(`${API}/api/admin/get/customers`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => response.json())
    .catch((err) => console.error(err));
};

// updateUserAccountStatus
export const updateUserAccountStatus = (userId) => {
  return fetch(`${API}/api/admin/user/${userId}/update/account/status`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => response.json())
    .catch((err) => console.error(err));
};
