import { API } from "../Backend";

export const getAllItems = () => {
  return fetch(`${API}/api/admin/get/all/items`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

// getItems
export const getItems = (_id) => {
  return fetch(`${API}/api/user/category/${_id}/get/items`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

// createItem
export const createItem = (data) => {
  console.log(data);

  return fetch(`${API}/api/admin/create/item`, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: data,
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

// updateItem
export const updateItem = (id, data) => {
  return fetch(`${API}/api/admin/item/${id}/update`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
    },
    body: data,
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

// deleteItem
export const deleteItem = (_id) => {
  return fetch(`${API}/api/admin/item/${_id}/delete`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

// addSizeAttribute
export const addSizeAttribute = (_id, data) => {
  return fetch(`${API}/api/admin/item/${_id}/create/size`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

// getItemSizes
export const getItemSizes = (_id) => {
  return fetch(`${API}/api/admin/item/${_id}/get/sizes`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

// deleteSize
export const deleteSize = (itemId, sizeId) => {
  return fetch(`${API}/api/admin/item/${itemId}/size/${sizeId}/delete`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

// updateSize
export const updateSize = (sizeId, data) => {
  return fetch(`${API}/api/admin/item/size/${sizeId}/update`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

// getExtras
export const getItemExtras = (itemId) => {
  return fetch(`${API}/api/admin/item/${itemId}/get/extras`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

// createExtra
export const createExtra = (itemId, data) => {
  return fetch(`${API}/api/admin/item/${itemId}/create/extra`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

// updateExtra
export const updateExtra = (extraId, data) => {
  return fetch(`${API}/api/admin/item/extra/${extraId}/update`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

// deleteExtra
export const deleteExtra = (itemId, extraId) => {
  return fetch(`${API}/api/admin/item/${itemId}/extra/${extraId}/delete`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

// addDiscountInItem
export const addDiscountInItem = (itemId, data) => {
  return fetch(`${API}/api/admin/item/${itemId}/add/discount`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

// createItemForCategory
export const createItemForCategory = (categoryId, data) => {
  return fetch(`${API}/api/admin/create/item/category/${categoryId}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: data,
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

export const getCategoryItems = (id) => {
  return fetch(`${API}/api/user/category/${id}/items`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

export const getItem = (id) => {
  return fetch(`${API}/api/admin/get/item/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};
