import React from "react";
import "./CategoryList.css";

import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import categoryImg from "../../Images/categoryImg.png";
import { Link } from "react-router-dom";

const CategoryList = ({ search, Categories, Edit, Delete }) => {
  // deleteHandler
  const deleteHandler = (_id) => {
    let confirm = window.confirm("Do you really want to delete this?");

    if (confirm) {
      Delete(_id);
    }
  };

  return (
    <div className="category-card-wrapper">
      {/* <div
        style={{
          border: "0.2px solid black",
          width: "100%",
          margin: "auto",
        }}
      ></div> */}

      {Categories && Categories.length == 0 && (
        <h3 style={{ margin: "20px", textAlign: "center" }}>No Categories!</h3>
      )}

      {Categories &&
        Categories.filter((cate) => {
          if (search && search.toLowerCase() == "") {
            return cate;
          } else if (
            cate.categoryName.toLowerCase().includes(search.toLowerCase())
          ) {
            return cate;
          }
        }).map((category) => {
          const { categoryName, _id, status, imageUrl } = category;
          return (
            <>
              <div key={_id} className="category-card">
                {/* <Link to={`/admin/dashboard/menu/category/${_id}/sub/categories`}> */}
                {/*  */}
                <div className="category-info">
                  <div className="img-wrapper">
                    <img
                      className={status ? "" : "categoryOff"}
                      src={imageUrl ? imageUrl : categoryImg}
                      alt="category-image"
                    />
                  </div>
                  <Link
                    to={`/admin/dashboard/menu/category/${_id}/sub/categories`}
                  >
                    <h5 className="ml-3">{categoryName}</h5>
                  </Link>
                </div>
                <div>
                  <IconButton
                    onClick={() => Edit(category)}
                    variant="contained"
                    // color="primary"
                  >
                    <EditIcon />
                  </IconButton>

                  <IconButton
                    onClick={() => deleteHandler(_id)}
                    variant="contained"
                    // color="secondary"
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
                {/*  */}
                {/* <Link /> */}
              </div>
              {/* <div
                style={{
                  border: "0.2px solid black",
                  width: "70%",
                  margin: "auto",
                }}
              ></div> */}
            </>
          );
        })}
    </div>
  );
};

export default CategoryList;
