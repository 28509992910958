import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import moment from "moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

// css
import "./Offers.css";

// helper functions
import {
  getAllOffers,
  deleteOffer,
  updateOffer,
} from "../../helpers/OfferHelper";
import { isAuthenticated } from "../../helpers/AuthHelper";

// components
import Sidebar from "../../Components/Sidebar/Sidebar";
import Header from "../header/Header";
import Loader from "../../Loader/Loader";
import { TextField, Button, Select, InputLabel } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import { Link, useHistory } from "react-router-dom";

// Table
import Table from "../../Tables/Offers/OffersTable";
import toast from "react-hot-toast";

// styles for modal
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    width: "30%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

// element for modal
// Modal.setAppElement("#root");

const Offers = () => {
  // AdminId or UserId
  const userId = isAuthenticated().data.user._id;

  // useState hooks
  const [AllOffers, setAllOffers] = useState([]);
  const [search, setSearch] = useState("");
  const [Loading, setLoading] = useState(true);
  const [Reload, setReload] = useState(false);

  // for edit
  const [Edit, setEdit] = useState("");
  const [EditModalOpen, setEditModalOpen] = useState(false);
  const [EditValues, setEditValues] = useState({
    discountName: "",
    discountType: "",
    discountTypeValue: "",
    minOrder: "",
    couponLimit: "",
    status: "",
    createdBy: "",
    minimumRequirement: "",
    minimumRequirementValue: "",
    startTime: "",
    startDate: "",
    endDate: "",
    endTime: "",
  });

  // destructuring
  const {
    discountName,
    discountType,
    discountTypeValue,
    minOrder,
    couponLimit,
    createdBy,
    minimumRequirement,
    minimumRequirementValue,
    startTime,
    startDate,
    endDate,
    endTime,
    active,
  } = EditValues;

  // getOffers method
  const getOffers = () => {
    getAllOffers()
      .then((data) => {
        console.table("OFFERS", data);
        setAllOffers(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("ERROR", err);
        setLoading(false);
      });
  };

  // useEffect
  useEffect(() => {
    getOffers();
  }, [Reload]);

  const changeHandler = (name) => (e) => {
    let value = e.target.value;
    setEditValues({ ...EditValues, [name]: value });
  };

  const editOfferHandler = (e) => {
    e.preventDefault();

    console.log("EDIT VALUES", EditValues);

    setLoading(true);

    let couponId = Edit._id;
    updateOffer(couponId, EditValues)
      .then((data) => {
        if (data.error) {
          alert(data.message);
        }
        setEditValues({
          discountName: "",
          discountType: "",
          discountTypeValue: "",
          startDate: "",
          endDate: "",
          minOrder: "",
          couponLimit: "",
          status: "",
          createdBy: "",
          minimumRequirement: "",
          minimumRequirementValue: "",
          startTime: "",
          startDate: "",
          endDate: "",
          endTime: "",
          createdBy: userId,
        });
        setEditModalOpen(false);
        setReload(!Reload);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setEditModalOpen(false);
        setLoading(false);
      });
  };

  // deleteOfferHandler
  const deleteOfferHandler = (id) => {
    setLoading(true);

    deleteOffer(id)
      .then((data) => {
        if (data.error) {
          alert(data.message);
        }
        setReload(!Reload);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
  };

  const dateHandler = (name) => (e) => {
    console.log("event in dateHandler", e);
    setEditValues({ ...EditValues, [name]: new Date(e).toISOString() });
  };

  const timeHandler = (name) => (e) => {
    console.log("event in timeHandler ", e);
    setEditValues({ ...EditValues, [name]: moment(e).format() });
  };

  const history = useHistory();

  console.log("Edit Values", EditValues);

  let minDate = new Date().toISOString().slice(0, 10);

  //* offers return
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div style={{ padding: "0" }} className="col-md-2">
            <Sidebar />
          </div>
          {/* Conditional rendering */}
          {Loading ? (
            <div
              className="col-md-10"
              style={{
                height: "90vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "rgb(246, 248, 251)",
              }}
            >
              <Loader />
            </div>
          ) : (
            <div
              style={{
                background: "rgb(246, 248, 251)",
              }}
              className="col-md-10"
            >
              <Header search={search} setSearch={setSearch} />
              <div className="header-wrapper">
                <h3
                  onClick={() => history.goBack()}
                  style={{ cursor: "pointer" }}
                  className="back"
                >
                  <ArrowBackIosIcon />
                  Back
                </h3>
                <button
                  onClick={() => history.push("/admin/dashboard/add/offer")}
                  className="user-btn"
                >
                  {/* <Link
                    style={{ textDecoration: "none", color: "white" }}
                    to="/admin/dashboard/add/offer"
                  > */}
                  Add Coupon
                  {/* </Link> */}
                </button>
              </div>

              {/* Modal for Edit */}
              {Edit && (
                <Modal
                  isOpen={EditModalOpen}
                  onRequestClose={closeEditModal}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  {/* Form Start */}
                  <form onSubmit={editOfferHandler}>
                    <h3 className="text-center">Edit Coupon</h3>
                    <TextField
                      style={{ width: "100%" }}
                      id="standard-basic"
                      label="Code"
                      defaultValue={discountName}
                      onChange={changeHandler("discountName")}
                    />
                    <br />
                    <br />

                    <TextField
                      style={{ width: "100%" }}
                      id="standard-basic"
                      label="Discount (in % or AED)"
                      defaultValue={discountTypeValue}
                      onChange={changeHandler("discountTypeValue")}
                    />
                    <br />
                    <br />

                    <TextField
                      style={{ width: "100%" }}
                      id="standard-basic"
                      label="Min. Order"
                      defaultValue={minimumRequirementValue}
                      onChange={changeHandler("minimumRequirementValue")}
                    />
                    <br />

                    <div style={{ width: "100%" }}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          autoOk={true}
                          style={{ width: "100%" }}
                          disableToolbar
                          variant="inline"
                          format="MM/dd/yyyy"
                          margin="normal"
                          id="date-picker-inline"
                          label="Date picker inline"
                          value={startDate ? startDate : moment().format()}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          minDate={minDate}
                          onChange={dateHandler("startDate")}
                        />

                        <KeyboardTimePicker
                          style={{ width: "100%" }}
                          margin="normal"
                          label="Start Time"
                          id="time-picker"
                          value={startTime}
                          onChange={timeHandler("startTime")}
                          KeyboardButtonProps={{
                            "aria-label": "change time",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        style={{ width: "100%" }}
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="End Date"
                        value={
                          EditValues.endDate
                            ? moment(EditValues.endDate).format()
                            : new moment().add(1, "day")._d
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        minDate={
                          startDate ? moment(startDate).toDate() : minDate
                        }
                        onChange={dateHandler("endDate")}
                      />

                      <KeyboardTimePicker
                        style={{ width: "100%" }}
                        margin="normal"
                        id="time-picker"
                        label="End Time"
                        value={endTime}
                        onChange={timeHandler("endTime")}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      />
                    </MuiPickersUtilsProvider>

                    <InputLabel id="demo-simple-select-label">
                      Discount Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      defaultValue={discountType}
                      onChange={changeHandler("discountType")}
                    >
                      <option value="PERCENTAGE">Percentage</option>
                      <option value="FIXED-VALUE">Fixed-Value</option>
                    </Select>
                    <br />
                    <br />

                    <InputLabel id="demo-simple-select-label">
                      Status
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={active}
                      onChange={changeHandler("active")}
                    >
                      <option value={true}>True</option>
                      <option value={false}>False</option>
                    </Select>
                    <br />
                    <br />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button type="submit" variant="contained" color="primary">
                        Update Coupon
                      </Button>

                      <Button
                        onClick={closeEditModal}
                        variant="contained"
                        color="secondary"
                      >
                        Cancel
                      </Button>
                    </div>
                  </form>
                  {/* Form End */}
                </Modal>
              )}
              {/* Edit Modal End */}

              <br />
              <Table
                Delete={(_id) => {
                  deleteOfferHandler(_id);
                }}
                Edit={(data) => {
                  console.log("DATA", data);
                  setEdit(data);
                  setEditValues({
                    discountName: data.discountName,
                    discountType: data.discountType,
                    discountTypeValue: data.discountTypeValue,
                    minimumRequirementValue: data.minimumRequirementValue,
                    active: data.active,
                    startDate: data.startDate,
                    endDate: data.endDate,
                    startTime: data.startTime,
                    endTime: data.endTime,
                  });
                  setEditModalOpen(true);
                }}
                search={search}
                rows={AllOffers}
                columns={[
                  "Code ",
                  "Discount Type",
                  "Discount",
                  "Start Date",
                  "Start Time",
                  "End Date",
                  "End Time",
                  "Status",
                  "Actions",
                ]}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Offers;
