import React, { useState } from "react";

import "./Header.css";

// Material
import { IconButton, MenuItem } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AccountCircle from "@material-ui/icons/AccountCircle";
import SearchIcon from "@material-ui/icons/Search";
import Profile from "../Profile/Profile";
import { Link } from "react-router-dom";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
    borderBottom: "1px dotted black",
  },
}));

const Header = ({ search, setSearch, Notifications, setNotifications }) => {
  const [Open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  let classes = useStyles();

  console.log("NOTIFICATIONS LENGTH", Notifications);
  console.log("NOTIFICATIONS TYPE", typeof Notifications);

  const searchHandler = (e) => {
    if (search !== undefined) {
      setSearch(e.target.value);
    } else {
      return;
    }
  };

  console.log("Search", search);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setNotifications(0);
  };

  let open = Boolean(anchorEl);
  let id = open ? "simple-popover" : undefined;

  return (
    <>
      <div className="my-header">
        <div className="together-1">
          <SearchIcon
            style={{
              margin: "9px 0 0 20px",
              zIndex: "10000000",
              position: "absolute",
              color: "#CACCCF",
            }}
          />
          <input
            onChange={searchHandler}
            placeholder="Search"
            className="search-bar"
            type="text"
            defaultValue={search}
          />
        </div>

        <div className="together-2 m-3">
          {/* <MenuItem> */}
          <IconButton
            onClick={Notifications > 0 ? handleClick : null}
            aria-label="show 11 new notifications"
            color="inherit"
          >
            {/* <Link to="/admin/dashboard/notifications"> */}
            <Badge
              badgeContent={Notifications > 0 ? Notifications : null}
              color="secondary"
            >
              <NotificationsIcon />
            </Badge>
            {/* </Link> */}
          </IconButton>
          {/* POPOVER */}
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Typography className={classes.typography}>
              {Notifications > 1
                ? `You Have ${Notifications} New Order Requests.`
                : `You Have ${Notifications} New Order Request.`}
            </Typography>
          </Popover>
          {/* POPOVER END */}
          {/* </MenuItem> */}
          {/* <MenuItem> */}
          <IconButton
            onClick={() => setOpen(!Open)}
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          {/* </MenuItem> */}
        </div>
      </div>
      <Profile Open={Open} setOpen={setOpen} />
    </>
  );
};

export default Header;
