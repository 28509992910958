import { API } from "../Backend";

// getOffers
export const getAllOffers = () => {
  return fetch(`${API}/api/admin/get/all/coupons`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

// updateOffer
export const updateOffer = (id, data) => {
  console.log("DATA BEFORE UPDATING", data);
  return fetch(`${API}/api/admin/coupon/${id}/update`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

// deleteOffer
export const deleteOffer = (id) => {
  return fetch(`${API}/api/admin/coupon/${id}/delete`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

// getOffersData
export const getOffersData = () => {
  return fetch(`${API}/api/admin/get/item/subcategories/users`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

// createOffer
export const createOffer = (adminId, data) => {
  return fetch(`${API}/api/admin/${adminId}/create/coupon`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};
