import React, { useState, useEffect } from "react";
import "./OrderDetails.css";

// Components
import Header from "../header/Header";
import Sidebar from "../Sidebar/Sidebar";
import Loader from "../../Loader/Loader";
import { useHistory, useParams } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import Table from "../../Tables/Orders/OrderDetailsTable";
import {
  getOrderDetails,
  acceptOrRejectOrder,
} from "../../helpers/orderHelper";
import toast, { Toaster } from "react-hot-toast";
import { isAuthenticated } from "../../helpers/AuthHelper";
import OrderStatus from "../OrderStatus/OrderStatus";

const OrderDetails = () => {
  const [Order, setOrder] = useState("");
  const [search, setSearch] = useState("");
  const [Loading, setLoading] = useState(true);
  const [Reload, setReload] = useState(false);

  const history = useHistory();
  const { orderId } = useParams();

  console.log("ORDER ID", orderId);

  // getOrder
  const getOrder = () => {
    getOrderDetails(orderId)
      .then((data) => {
        console.log("ORDER DETAILS", data);
        setOrder(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        toast.error("Something went wrong!");
      });
  };

  useEffect(() => {
    getOrder();
  }, [Reload]);

  // acceptOrRejectHandler
  const acceptOrRejectHandler = (orderId, answer) => {
    setLoading(true);

    let adminId = isAuthenticated().data.user;
    const { _id } = adminId;
    console.log("ADMIN ID", _id);

    acceptOrRejectOrder(_id, orderId, { isAccepted: answer })
      .then((data) => {
        console.log(data);
        setLoading(false);
        setReload(!Reload);
        toast.success(data.message);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error("Something went wrong!");
      });
  };

  const {
    _id,
    status,
    delieveryType,
    customerNote,
    paymentType,
    createdAt,
    totalAmount,
    delieveryAddress,
    contactNumber,
    user,
    restro,
    coupon,
  } = Order;

  // Component Return
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div style={{ padding: "0" }} className="col-md-2">
            <Sidebar />
          </div>
          {Loading ? (
            <div
              style={{
                height: "90vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="col-md-10"
            >
              <Loader />
            </div>
          ) : (
            <div
              className="col-md-10"
              style={{
                backgroundColor: "#F6F8FB",
                height: "100vh",
                padding: "3px",
              }}
            >
              <Toaster />
              <Header search={search} setSearch={setSearch} />
              <div className="header-wrapper">
                <h3
                  style={{ cursor: "pointer" }}
                  onClick={() => history.goBack()}
                >
                  <ArrowBackIosIcon />
                  Back to Orders
                </h3>
              </div>
              <OrderStatus currentStatus={status} />
              <div className="details-wrapper">
                <h3 style={{ fontSize: "20px" }}>
                  Restaurant Name -
                  <span>
                    {restro && restro.restroName ? restro.restroName : "N/A"}
                  </span>
                </h3>
                <hr />
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "3fr 3fr 2fr",
                  }}
                >
                  {/* <h3 style={{ fontSize: "20px" }}>
                    Restro Name{" "}
                    <span>
                      {restro && restro.restroName ? restro.restroName : "N/A"}
                    </span>
                  </h3> */}
                  <div>
                    <h3 style={{ fontSize: "20px" }}>
                      Order id #<span>{_id}</span>
                    </h3>
                    <h5>
                      {delieveryType == "Delivery"
                        ? "Home Delivery"
                        : "Take Away"}
                    </h5>
                    <h5>
                      {delieveryAddress && delieveryType == "Delivery"
                        ? `Address - ${delieveryAddress}`
                        : ""}
                    </h5>
                  </div>
                  <div>
                    <h3 style={{ fontSize: "20px", color: "#717171" }}>
                      Customer Note:{" "}
                      <span style={{ color: "black" }}>
                        {customerNote && customerNote.length > 1
                          ? `${customerNote}`
                          : "N/A"}
                      </span>
                    </h3>
                    {/* <h5 style={{ fontSize: "16px" }}>

                    </h5> */}
                    <h5>
                      Contact : {user && user.contact ? user.contact : "N/A"}
                    </h5>
                    <h5>Username : {user && user.name}</h5>
                  </div>

                  <div style={{ textAlign: "right" }}>
                    <h3 style={{ fontSize: "20px" }}>
                      {createdAt && createdAt.slice(11, -8) >= 12
                        ? `${createdAt && createdAt.slice(11, -8)} AM`
                        : `${createdAt && createdAt.slice(11, -8)} PM`}
                      , {createdAt && createdAt.slice(0, 10)}
                    </h3>
                    <h5>AED {totalAmount}</h5>
                    <h5>
                      {paymentType === "COD" ? "Cash on Delivery" : "Prepaid"}
                    </h5>
                  </div>
                </div>
                <hr />
                <Table
                  coupon={coupon}
                  totalAmount={totalAmount}
                  search={search}
                  rows={Order.orderItems}
                  columns={[" Name", "Extras", "", "", "Size", "Qty", "Price"]}
                />
                {status === "PENDING" && (
                  <div
                    style={{
                      margin: "50px auto",
                      width: "35%",
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <button
                      onClick={() => acceptOrRejectHandler(_id, false)}
                      style={{
                        width: "200px",
                        padding: "10px",
                        borderRadius: "5px",
                        backgroundColor: "#4A4B4D",
                        color: "white",
                        border: "none",
                      }}
                    >
                      Reject
                    </button>
                    <button
                      onClick={() => acceptOrRejectHandler(_id, true)}
                      style={{
                        width: "200px",
                        padding: "10px",
                        borderRadius: "5px",
                        background: "#00A0A7",
                        color: "white",
                        border: "none",
                      }}
                    >
                      Accept
                    </button>
                  </div>
                )}

                {/*  */}
              </div>
              {/* DETAILS WRAPPER END */}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default OrderDetails;
