import React, { Component } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

// Helpers
import { isAuthenticated } from "../src/helpers/AuthHelper";

// Components
import Login from "./Components/Login/Login";
import CheckOtp from "./Components/Otp/CheckOtp";
import Dashboard from "./Components/Dashboard/Dashboard";
import Offers from "./Components/Offers/Offers";
import TicketReply from "./Components/Help/TicketReply";
import Users from "./Components/Users/Users";
import Order from "./Components/Order/Order";
import Account from "./Components/Account/Account";
import Setting from "./Components/Settings/Setting";
import SetPassword from "./Components/Users/SetPassword";
import Categories from "./Components/Categories/Categories";
import Restro from "./Components/Restraurant/Restro";
import AddOffer from "./Components/Offers/AddOffer";
import SubCategories from "./Components/SubCategories/SubCategories";
import Items from "./Components/Items/Items";
import Item from "./Components/Items/Item";
import ChatComp from "./Components/Chat/ChatComp";
import OrderDetails from "./Components/Order/OrderDetails";
import Notifications from "./Components/Notifications/Notifications";
import Help from "./Components/Help/Help";
import Customers from "./Components/Customers/Customers";
import Transactions from "./Components/Transactions/Transactions";
import ItemForCategory from "./Components/Items/ItemPageForCategory";
import ItemForEdit from "./Components/Items/ItemForEdit";

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/admin/:adminId/check/otp" component={CheckOtp} />

        <Route
          exact
          path="/admin/:adminId/set/password"
          component={SetPassword}
        />

        {/* Private Routes */}
        <PrivateRoute
          exact
          path="/admin/dashboard/overview"
          component={Dashboard}
        />

        <PrivateRoute
          exact
          path="/admin/dashboard/order/:orderId"
          component={OrderDetails}
        />

        <PrivateRoute exact path="/admin/dashboard/users" component={Users} />

        <PrivateRoute exact path="/admin/dashboard/offers" component={Offers} />

        <PrivateRoute
          exact
          path="/admin/dashboard/transactions"
          component={Transactions}
        />

        <PrivateRoute
          exact
          path="/admin/dashboard/add/offer"
          component={AddOffer}
        />

        <PrivateRoute
          exact
          path="/admin/dashboard/help/ticket/:ticketId/reply"
          component={TicketReply}
        />

        <PrivateRoute exact path="/admin/dashboard/orders" component={Order} />

        <PrivateRoute
          exact
          path="/admin/dashboard/notifications"
          component={Notifications}
        />

        <PrivateRoute
          exact
          path="/admin/dashboard/menu"
          component={Categories}
        />

        <PrivateRoute
          exact
          path="/admin/dashboard/menu/category/:categoryId/sub/categories"
          component={SubCategories}
        />

        <PrivateRoute
          exact
          path="/admin/dashboard/menu/sub/category/:subCategoryId/items"
          component={Items}
        />

        <PrivateRoute
          exact
          path="/admin/dashboard/menu/category/:categoryId/create/items"
          component={ItemForCategory}
        />

        <PrivateRoute
          exact
          path="/admin/dashboard/menu/item/:itemId/edit"
          component={ItemForEdit}
        />

        <PrivateRoute
          exact
          path="/admin/dashboard/menu/create/items"
          component={Item}
        />

        <PrivateRoute
          exact
          path="/admin/dashboard/account"
          component={Account}
        />

        <PrivateRoute
          exact
          path="/admin/dashboard/setting"
          component={Restro}
        />

        <PrivateRoute exact path="/admin/dashboard/help" component={ChatComp} />

        <PrivateRoute
          exact
          path="/admin/dashboard/all/customers"
          component={Customers}
        />

        <PrivateRoute
          exact
          path="/admin/dashboard/setting/add/restraurant"
          component={Setting}
        />

        <Redirect from="/" to={`/admin/dashboard/overview`} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
