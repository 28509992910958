import React, { useState, useEffect } from "react";
import Axios from "axios";

import Header from "../header/Header";
import Sidebar from "../Sidebar/Sidebar";

// table
import Table from "../../Tables/Help/HelpTable";
import Loader from "../../Loader/Loader";
import { API } from "../../Backend";

const Help = () => {
  // useState hooks
  const [Tickets, setTickets] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");

  // getTickets
  const getTickets = () => {
    Axios.get(`${API}/api/admin/all/tickets`, {
      headers: {
        Accept: "application/json",
      },
    })
      .then((data) => {
        console.table(data.data);
        setTickets(data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  // useEffect hooks
  useEffect(() => {
    getTickets();
  }, []);

  // help component return
  return (
    <div className="container-fluid">
      <div className="row">
        <div style={{ padding: "0" }} className="col-md-2">
          <Sidebar />
        </div>

        {Loading ? (
          <div
            className="col-md-10"
            style={{
              height: "90vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader />
          </div>
        ) : (
          <div
            style={{ backgroundColor: "rgb(246, 248, 251)" }}
            className="col-md-10"
          >
            <Header setSearch={setSearch} search={search} />
            <div className="mt-4">
              <h1>Help</h1>
              <h3>
                All Tickets -{" "}
                <span className="badge bg-secondary">
                  {Tickets && Tickets.length}
                </span>
              </h3>
            </div>
            {Loading ? (
              <Loader />
            ) : (
              <Table
                search={search}
                rows={Tickets}
                columns={["Id", "Title", "Status", "Description", "Action"]}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Help;
