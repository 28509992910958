import React, { useEffect, useState } from "react";

// components
import Loader from "../../Loader/Loader";
import Sidebar from "../../Components/Sidebar/Sidebar";
import Header from "../header/Header";

import { useHistory, useParams } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

// helpers
import { getItems, deleteItem } from "../../helpers/itemHelper";

// material-ui
import {
  IconButton,
  TextField,
  Button,
  InputLabel,
  Select,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import Modal from "react-modal";

const Items = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      width: "30%",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  // parameter extraction
  const { subCategoryId } = useParams();

  console.log(subCategoryId);

  // useState hooks
  const [search, setSearch] = useState("");
  const [Edit, setEdit] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [Loading, setLoading] = useState(true);
  const [Items, setItems] = useState([]);
  const [Reload, setReload] = useState(false);
  const [Values, setValues] = useState({
    amount: "",
    description: "",
    name: "",
    discountedPrice: "",
    discountedPeriod: "",
  });

  const { amount, description, name, discountedPeriod, discountedPrice } =
    Values;

  // getAllItems
  const getAllItems = (subCategoryId) => {
    getItems(subCategoryId)
      .then((data) => {
        setItems(data);
        console.log("ITEMS", data);
        setLoading(false);
      })
      .catch((err) => console.error(err));
  };

  // useEffect
  useEffect(() => {
    getAllItems(subCategoryId);
  }, [Reload]);

  // deleteHandler
  const deleteHandler = (_id) => {
    let confirm = window.confirm("Do you really want to delete this item?");

    if (confirm) {
      setLoading(true);

      deleteItem(_id)
        .then((data) => {
          console.log(data);
          setReload(!Reload);
          toast.success(data.message);
        })
        .catch((err) => console.error(err));
    }
  };

  const history = useHistory();

  const changeEditHandler = (name) => (e) => {};

  console.log("Edit", Edit);
  console.log("Values", Values);

  function closeModal() {
    setIsOpen(false);
  }

  // component return
  return (
    <div className="container-fluid">
      <div className="row">
        <div style={{ padding: "0" }} className="col-md-2">
          <Sidebar />
        </div>
        {Loading ? (
          <div
            className="col-md-10"
            style={{
              height: "90vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader />
          </div>
        ) : (
          <div className="col-md-10">
            <Header search={search} setSearch={setSearch} />
            <Toaster />

            {/* TOP BAR */}
            <div className="top-bar">
              <h3
                onClick={() => history.goBack()}
                style={{ cursor: "pointer" }}
                className="back"
              >
                <ArrowBackIosIcon />
                Back to Sub Categories
              </h3>
              <button
                onClick={() =>
                  history.push("/admin/dashboard/menu/create/items")
                }
                style={{ width: "15%" }}
                className="user-btn"
              >
                Add New Item
              </button>
            </div>
            {/* TOP BAR END */}
            {/* Edit Modal */}
            {Edit && (
              <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
              >
                <form>
                  <h3 className="text-center">Update Item</h3>
                  <TextField
                    style={{ width: "100%" }}
                    id="standard-basic"
                    label="Name"
                    // defaultValue={Edit.categoryName}
                    onChange={changeEditHandler("categoryName")}
                  />
                  <br />
                  <br />

                  <TextField
                    style={{ width: "100%" }}
                    id="standard-basic"
                    label="Name (in arabic)"
                    // defaultValue={Edit.arabicCategoryName}
                    onChange={changeEditHandler("arabicCategoryName")}
                  />
                  <br />
                  <br />

                  <TextField
                    type="number"
                    style={{ width: "100%" }}
                    id="standard-basic"
                    label="Amount"
                    // defaultValue={Edit.arabicCategoryName}
                    onChange={changeEditHandler("amount")}
                  />
                  <br />
                  <br />

                  <TextField
                    style={{ width: "100%" }}
                    id="outlined-multiline-static"
                    label="Description"
                    multiline
                    rows={4}
                    defaultValue=""
                    variant="outlined"
                  />
                  <br />
                  <br />

                  <TextField
                    style={{ width: "100%" }}
                    id="outlined-multiline-static"
                    label="Description (in arabic)"
                    multiline
                    rows={4}
                    defaultValue=""
                    variant="outlined"
                  />
                  <br />
                  <br />

                  <Button variant="contained" component="label">
                    Upload Image
                    <input
                      onChange={changeEditHandler("photo")}
                      type="file"
                      hidden
                    />
                  </Button>
                  <br />
                  <br />

                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    defaultValue={Edit.status}
                    onChange={changeEditHandler("status")}
                  >
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </Select>
                  <br />
                  <br />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button type="submit" variant="contained" color="primary">
                      Update Item
                    </Button>

                    <Button
                      onClick={closeModal}
                      variant="contained"
                      color="secondary"
                    >
                      Cancel
                    </Button>
                  </div>
                </form>
              </Modal>
            )}
            {/* Edit Modal END*/}

            {Items && Items.length === 0 && (
              <h3
                style={{ width: "100%", margin: "20px", textAlign: "center" }}
              >
                No Items Added!
              </h3>
            )}
            <div className="user-cards-wrapper">
              {Items &&
                Items.filter((data) => {
                  if (search && search.toLowerCase() == "") {
                    return data;
                  } else if (
                    data.name.toLowerCase().includes(search.toLowerCase())
                  ) {
                    return data;
                  }
                }).map((itemData) => {
                  const { _id, name, amount, imageUrl } = itemData;
                  return (
                    <div key={_id} className="user-card">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          // justifyContent: "space-evenly",
                          height: "100%",
                        }}
                      >
                        <div
                          style={{
                            width: "30%",
                            height: "100%",
                            marginRight: "15px",
                          }}
                          className="user-img-wrapper"
                        >
                          <img
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                            src={imageUrl}
                            alt="user-img"
                          />
                        </div>
                        <div className="user-info-wrapper">
                          <h4>{name}</h4>
                          <h4>AED {amount}</h4>
                        </div>
                      </div>
                      <br />
                      <div>
                        <div
                          style={{
                            display: "flex",
                            position: "absolute",
                            bottom: "5px",
                            right: "5px",
                          }}
                          className="buttons"
                        >
                          <IconButton
                            onClick={() =>
                              history.push(
                                `/admin/dashboard/menu/item/${_id}/edit`
                              )
                            }
                            variant="contained"
                            color="primary"
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => deleteHandler(_id)}
                            variant="contained"
                            color="secondary"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Items;
