import React, { useState, useEffect } from "react";

import Sidebar from "../Sidebar/Sidebar";
import Loader from "../../Loader/Loader";
import Header from "../header/Header";
import DiscountName from "./DiscountName";
import DiscountType from "./DiscountType";
import Requirements from "./Requirements";
import Applicable from "./Applicable";
import CustomerType from "./CustomerType";
import Duration from "./Duration";
import Modal from "../Modal/Modal";

// helpers
import { useHistory, Link } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

// material-ui
import { Button } from "@material-ui/core";
import { createOffer } from "../../helpers/OfferHelper";
import { isAuthenticated } from "../../helpers/AuthHelper";
import toast, { Toaster } from "react-hot-toast";
import { Redirect } from "react-router-dom";
import moment from "moment";

const AddOffer = () => {
  const [Loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [FormError, setFormError] = useState({
    discountName: false,
    discountType: false,
    discountTypeValue: false,
    minimumRequirement: false,
    minimumRequirementValue: false,
    startDate: false,
    startTime: false,
    endDate: false,
    endTime: false,
    active: false,
  });
  const [Values, setValues] = useState({
    discountName: "",
    discountType: "PERCENTAGE",
    discountTypeValue: "",
    minimumRequirement: "NO-REQUIREMENT",
    minimumRequirementValue: "0",
    startDate: moment().format(),
    startTime: moment().format(),
    endDate: new moment().add(1, "day")._d,
    endTime: moment().format(),
    active: true,
    createdBy: isAuthenticated().data.user._id,
  });

  const history = useHistory();

  //? submitHandler
  const submitHandler = (e) => {
    e.preventDefault();

    if (Validate()) {
      setLoading(true);

      createOffer(isAuthenticated().data.user._id, Values)
        .then((data) => {
          console.log(data);
          setValues({
            discountName: "",
            discountType: "PERCENTAGE",
            discountTypeValue: "0",
            minimumRequirement: "NO-REQUIREMENT",
            minimumRequirementValue: "0",
            startDate: "",
            startTime: "",
            endDate: "",
            endTime: "",
            createdBy: isAuthenticated().data.user._id,
          });
          setLoading(false);
          setRedirect(true);
          // toast.success(data.message);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          toast.error("Something went wrong!");
        });
    }
  };

  // redirectHandler
  const redirectHandler = () => {
    if (redirect) {
      return <Redirect to="/admin/dashboard/offers" />;
    }
  };

  const Validate = () => {
    let value = true;

    let err = {
      discountName: false,
      discountType: false,
      discountTypeValue: false,
      minimumRequirement: false,
      minimumRequirementValue: false,
      startDate: false,
      startTime: false,
      endDate: false,
      endTime: false,
      active: false,
    };

    if (Values.discountName == "") {
      value = false;
      err.discountName = "Please Enter a Discount name first!";
    }

    if (Values.discountTypeValue == "") {
      value = false;
      err.discountTypeValue = "Please Enter the Discount Type Value first!";
    }

    setFormError({ ...err });
    return value;
  };

  // component return
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div style={{ padding: "0" }} className="col-md-2">
            <Sidebar />
          </div>
          {Loading ? (
            <div
              className="col-md-9"
              style={{
                height: "90vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "rgb(246, 248, 251)",
              }}
            >
              <Loader />
            </div>
          ) : (
            <div className="col-md-10 mb-5">
              {redirectHandler()}
              <Toaster />
              <Header />
              <br />
              <div className="header-wrapper">
                <h3
                  onClick={() => history.goBack()}
                  style={{ cursor: "pointer" }}
                  className="back"
                >
                  <ArrowBackIosIcon />
                  Back
                </h3>
              </div>
              <h3>Add Offer</h3>
              <DiscountName
                FormError={FormError}
                Values={Values}
                setValues={setValues}
              />
              <DiscountType
                FormError={FormError}
                Values={Values}
                setValues={setValues}
              />
              {/* <Applicable Data={Data} Values={Values} setValues={setValues} /> */}
              <Requirements Values={Values} setValues={setValues} />
              {/* <CustomerType Data={Data} Values={Values} setValues={setValues} /> */}
              <Duration
                FormError={FormError}
                Values={Values}
                setValues={setValues}
                setLoading={setLoading}
              />
              <Button
                onClick={submitHandler}
                className="m-3"
                variant="contained"
                color="primary"
              >
                Add Offer
              </Button>
              <Button
                onClick={() => history.goBack()}
                variant="contained"
                color="secondary"
              >
                Cancel
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AddOffer;
