import { API } from "../Backend";

//* login
export const login = (data) => {
  return fetch(`${API}/api/admin/login`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .catch((err) => console.error(err));
};

//* setAdminId in localStorage for Otp Request
export const setIdInLocalStorage = (id, next) => {
  if (typeof window !== undefined) {
    localStorage.setItem("Id", JSON.stringify(id));
    next();
  }
};

export const checkOtp = (adminId, otp) => {
  console.log("ADMIN ID", adminId);
  console.log("OTP", otp);

  return fetch(`${API}/api/admin/${adminId}/check/otp`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ otp }),
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

//* authenticate for setting token in localStorage
export const authenticate = (data, next) => {
  if (!typeof window !== undefined) {
    localStorage.setItem("auth_token", JSON.stringify(data));
    next();
  }
};

//* for checking the token in the localStorage
export const isAuthenticated = () => {
  if (typeof window == undefined) {
    return false;
  }

  //* if there is auth present in localStorage
  if (localStorage.getItem("auth_token")) {
    return JSON.parse(localStorage.getItem("auth_token"));
  } else {
    return false;
  }
};

//* logout
export const logout = (next) => {
  if (localStorage.getItem("auth_token")) {
    localStorage.removeItem("auth_token");

    next();

    return fetch(`${API}/admin/logout`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .catch((err) => console.log(err));
  }
};
