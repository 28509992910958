import React from "react";

// components
import Sidebar from "../Sidebar/Sidebar";

const Account = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div style={{ padding: "0" }} className="col-md-2">
          <Sidebar />
        </div>
        <div className="col-md-10">
          <h1>Account Component</h1>
        </div>
      </div>
    </div>
  );
};

export default Account;
