import React, { useState, useEffect } from "react";
import "./Items.css";

// modal

import { TextField, Button, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";

// components
import Loader from "../../Loader/Loader";

import toast, { Toaster } from "react-hot-toast";
import AddModal from "../Modal/Modal";
import { useHistory } from "react-router-dom";

// helpers
import {
  addSizeAttribute,
  getItemSizes,
  deleteSize,
  updateSize,
  getItemExtras,
} from "../../helpers/itemHelper";
import ExtraComp from "./Extras";

import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "30%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

const Attributes = ({ setCurrentPage }) => {
  const [SubCategories, setSubCategories] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [Reload, setReload] = useState(false);
  const [Sizes, setSizes] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [EditModalOpen, setEditModalOpen] = useState(false);
  const [ExtrasData, setExtrasData] = useState([]);
  const [Edit, setEdit] = useState("");

  const [Values, setValues] = useState({
    name: "",
    price: "",
    sizeArabicName: "",
  });

  const [EditValues, setEditValues] = useState({
    _id: "",
    size: "",
    price: "",
    sizeArabicName: "",
  });

  //? history
  let history = useHistory();

  // destructuring
  const { name, price, sizeArabicName } = Values;

  // useEffect
  useEffect(() => {
    if (localStorage.getItem("itemId")) {
      setLoading(true);

      let itemId = JSON.parse(localStorage.getItem("itemId"));
      let { _id } = itemId;

      console.log("ITEM ID", _id);

      getItemExtras(_id)
        .then((data) => {
          console.log("EXTRAS", data);
          setExtrasData(data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });

      getItemSizes(_id)
        .then((data) => {
          setSizes(data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [Reload]);

  // sizeChangeHandler
  const sizeChangeHandler = (name) => (e) => {
    let value = e.target.value.trim();
    setValues({ ...Values, [name]: value });
  };

  // addSizeHandler
  const addSizeHandler = (e) => {
    e.preventDefault();

    setLoading(true);

    if (!localStorage.getItem("itemId")) {
      setLoading(false);
      toast.error("Please make one item first!");
      return;
    }

    let itemId = JSON.parse(localStorage.getItem("itemId"));
    let { _id } = itemId;

    console.log("ITEM ID", _id);

    addSizeAttribute(_id, Values)
      .then((data) => {
        if (data.error) {
          setLoading(false);
          toast.error(data.message);
          return "";
        }
        console.log(data);
        setReload(!Reload);
        setIsOpen(false);
        setValues({
          name: "",
          price: "",
        });
        setLoading(false);
        toast.success(data.message);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const updateHandler = (e) => {
    e.preventDefault();

    setLoading(true);

    updateSize(Edit._id, EditValues)
      .then((data) => {
        if (data.error) {
          toast.error(data.message);
          setLoading(false);
          return "";
        }
        console.log(data);
        setEdit("");
        setEditValues({
          _id: "",
          size: "",
          price: "",
        });
        setEditModalOpen(false);
        setLoading(false);
        setReload(!Reload);
        toast.success(data.message);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);

        toast.error("Something went wrong!");
      });
  };

  // deleteSizeHandler
  const deleteSizeHandler = (sizeId) => {
    let confirm = window.confirm("Do you really want to delete this?");

    if (confirm) {
      let item = JSON.parse(localStorage.getItem("itemId"));
      let { _id } = item;

      deleteSize(_id, sizeId)
        .then((data) => {
          console.log(data);
          setReload(!Reload);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // redirectHandler
  const redirectHandler = () => {
    if (!localStorage.getItem("itemId")) {
      toast.error("Please make One Item First!");
      return "";
    } else {
      setLoading(true);
      localStorage.removeItem("itemId");
      history.goBack();
    }
  };

  // closeModal
  const closeModal = () => {
    setEditModalOpen(false);
  };

  console.log("VALUES", Values);
  console.log("EDIT", Edit);
  console.log("SIZES", Sizes);
  console.log("EDIT Values", EditValues);

  // component return
  return (
    <div>
      {Loading ? (
        <div
          style={{
            width: "100%",
            minHeight: "60vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="col-md-10"
        >
          <Loader />
        </div>
      ) : (
        <div style={{ margin: "10px" }}>
          <h3>Size</h3>
          <div style={{ boxShadow: "none" }} className="size-div-wrapper">
            {/*  */}
            {Sizes &&
              Sizes.map((d, indx) => {
                const { _id, size, price } = d;
                return (
                  <div
                    key={indx}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      boxShadow: "0 0 4px rgba(0,0,0,0.4)",
                      padding: "10px",
                    }}
                  >
                    <h3>
                      {size} (AED {price})
                    </h3>
                    <div>
                      <IconButton
                        onClick={() => {
                          setEdit(d);
                          setEditValues({
                            _id: _id,
                            size: size,
                            price: price,
                          });
                          setEditModalOpen(true);
                        }}
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => deleteSizeHandler(_id)}
                        color="secondary"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </div>
                );
              })}
            {/*  */}

            {/* Add Modal for Size*/}
            <AddModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen}>
              <form onSubmit={addSizeHandler}>
                <h3 style={{ textAlign: "center" }}>Add Attribute</h3>
                <hr />
                <TextField
                  style={{ width: "100%" }}
                  id="standard-basic"
                  label="Name"
                  defaultValue={name}
                  onChange={sizeChangeHandler("name")}
                />
                <br />

                <TextField
                  style={{ width: "100%" }}
                  id="standard-basic"
                  label="Name (in arabic)"
                  defaultValue={sizeArabicName}
                  onChange={sizeChangeHandler("sizeArabicName")}
                />
                <br />
                <TextField
                  type="number"
                  style={{ width: "100%" }}
                  id="standard-basic"
                  label="Price (AED)"
                  defaultValue={price}
                  onChange={sizeChangeHandler("price")}
                />
                <br />
                <br />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button type="submit" variant="contained" color="primary">
                    Add Attribute
                  </Button>
                  <Button
                    onClick={() => setIsOpen(false)}
                    variant="contained"
                    color="secondary"
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </AddModal>
            {/* Add Modal End */}

            {/* Edit Modal Start */}
            {Edit && (
              <Modal
                isOpen={EditModalOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
              >
                <form onSubmit={updateHandler}>
                  <h3 style={{ textAlign: "center" }}>Update Attribute</h3>
                  <hr />
                  <TextField
                    style={{ width: "100%" }}
                    id="standard-basic"
                    label="Name"
                    defaultValue={Edit.size}
                    onChange={(e) =>
                      setEditValues({
                        ...EditValues,
                        size: e.target.value.trim(),
                      })
                    }
                  />
                  <br />

                  <TextField
                    style={{ width: "100%" }}
                    id="standard-basic"
                    label="Name (in arabic)"
                    defaultValue={Edit.sizeArabicName}
                    onChange={(e) =>
                      setEditValues({
                        ...EditValues,
                        sizeArabicName: e.target.value.trim(),
                      })
                    }
                  />
                  <br />
                  <TextField
                    type="number"
                    style={{ width: "100%" }}
                    id="standard-basic"
                    label="Price (AED)"
                    defaultValue={Edit.price}
                    onChange={(e) =>
                      setEditValues({
                        ...EditValues,
                        price: e.target.value.trim(),
                      })
                    }
                  />
                  <br />
                  <br />
                  <Button type="submit" variant="contained" color="primary">
                    Update Attribute
                  </Button>
                </form>
              </Modal>
            )}
            {/* Edit Modal End */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
                boxShadow: "0 0 4px rgba(0,0,0,0.4)",
              }}
            >
              <Button
                onClick={() => setIsOpen(true)}
                variant="contained"
                color="primary"
              >
                <AddIcon />
                Add Attributes
              </Button>
            </div>
          </div>
          <hr />
          <h3>Extra</h3>
          <ExtraComp
            setReload={setReload}
            ExtrasData={ExtrasData}
            Reload={Reload}
            setLoading={setLoading}
          />
          <div className="text-center mt-3">
            <button
              onClick={redirectHandler}
              type="submit"
              style={{
                padding: "15px",
                width: "30%",
                height: "fit-content",
                backgroundColor: "black",
                color: "white",
                border: "none",
                position: "absolute",
                left: "42.57%",
                right: "23.54%",
                top: "80.55%",
                bottom: "3.92%",

                background: "#00A0A7",
                borderRadius: "5px",
              }}
            >
              Save Changes and Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Attributes;
