import React from "react";
import "./SubCategoryList.css";

import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import categoryImg from "../../Images/categoryImg.png";
import { Link } from "react-router-dom";

const SubCategoryList = ({ search, SubCategories, Edit, Delete }) => {
  // deleteHandler
  const deleteHandler = (_id) => {
    let confirm = window.confirm("Do you really want to delete this?");

    if (confirm) {
      Delete(_id);
    }
  };

  return (
    <div className="category-card-wrapper">
      {/* <div
        style={{
          border: "0.2px solid black",
          width: "100%",
          margin: "auto",
        }}
      ></div> */}

      {SubCategories && SubCategories.length == 0 && (
        <h3 style={{ margin: "20px", textAlign: "center" }}>
          No Subcategories!
        </h3>
      )}

      {SubCategories &&
        SubCategories.filter((cate) => {
          if (search && search.toLowerCase() == "") {
            return cate;
          } else if (
            cate.subCategoryName &&
            cate.subCategoryName.toLowerCase().includes(search.toLowerCase())
          ) {
            return cate;
          }
        }).map((category) => {
          console.log(category);
          return (
            <>
              <div key={category._id} className="category-card">
                {/* <Link to={`/admin/dashboard/menu/category/${_id}/sub/categories`}> */}
                {/*  */}
                <div className="category-info">
                  <div className="img-wrapper">
                    <img
                      className={category.active ? "" : "categoryOff"}
                      src={
                        category.imageUrl
                          ? category.imageUrl
                          : category.categoryImg
                      }
                      alt="category-image"
                    />
                  </div>
                  <Link
                    to={`/admin/dashboard/menu/sub/category/${category._id}/items`}
                  >
                    <h5 className="ml-3">
                      {category.subCategoryName
                        ? category.subCategoryName
                        : category.name}
                    </h5>
                  </Link>
                </div>
                <div>
                  <IconButton
                    onClick={() => Edit(category)}
                    variant="contained"
                    // color="primary"
                  >
                    <EditIcon />
                  </IconButton>

                  <IconButton
                    onClick={() => deleteHandler(category._id)}
                    variant="contained"
                    // color="secondary"
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
                {/*  */}
                {/* <Link /> */}
              </div>
            </>
          );
        })}
    </div>
  );
};

export default SubCategoryList;
